import {Tooltip} from '@wandb/weave/components/Tooltip';
import React, {useEffect, useRef, useState} from 'react';

import {
  AccountIcon,
  AccountIconLocation,
} from '../../../pages/Billing/AccountSettings/AccountIcon';
import {useViewer} from '../../../state/viewer/hooks';
import {maybePluralize} from '../../../util/uihelpers';
import * as S from './ProfileMenu.styles';
import {Account, AccountType} from './types';

type AccountSelectorAccountInfoProps = {
  account: Account | null;
  isSelectedAccount: boolean;
  location: AccountIconLocation;
};

export function AccountSelectorAccountInfo({
  account,
  isSelectedAccount,
  location,
}: AccountSelectorAccountInfoProps): JSX.Element {
  const memberCountText =
    ' · ' + maybePluralize(account?.memberCount ?? 0, 'user');

  const viewer = useViewer();
  const isAccountSettingsHeader =
    location === AccountIconLocation.AccountSettingsHeader;
  const accountName =
    account?.accountType === AccountType.Personal &&
    account?.isDummyAccount !== true
      ? isAccountSettingsHeader
        ? viewer?.name
        : 'Personal'
      : account?.name;

  const accountNameRef = useRef<HTMLDivElement>(null);

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const element = accountNameRef.current;
    if (element) {
      const isOverflowing = element.scrollWidth > element.clientWidth;
      setShowTooltip(isOverflowing);
    }
  }, [accountName]);

  if (account == null) {
    return <></>;
  }

  return (
    <S.ProfileMenuAccountButton
      isAccountSettingsHeader={isAccountSettingsHeader}
      data-test={`account-${accountName}`}>
      <AccountIcon
        accountType={account?.accountType}
        location={location}
        isSelected={isSelectedAccount}
      />
      <S.ProfileMenuAccountTextContainer>
        {showTooltip && (
          <Tooltip
            content={accountName}
            position="top center"
            trigger={
              <S.ProfileMenuAccountName
                ref={accountNameRef}
                isAccountSettingsHeader={isAccountSettingsHeader}>
                {accountName}
              </S.ProfileMenuAccountName>
            }
          />
        )}
        {!showTooltip && (
          <S.ProfileMenuAccountName
            ref={accountNameRef}
            isAccountSettingsHeader={isAccountSettingsHeader}>
            {accountName}
          </S.ProfileMenuAccountName>
        )}
        <S.ProfileMenuAccountInfo
          isAccountSettingsHeader={isAccountSettingsHeader}>
          {account?.accountType === AccountType.Personal
            ? isAccountSettingsHeader
              ? 'Personal'
              : 'Free'
            : account?.accountType + memberCountText}
        </S.ProfileMenuAccountInfo>
      </S.ProfileMenuAccountTextContainer>
    </S.ProfileMenuAccountButton>
  );
}
