import {Annotation, Connector, Label, LineSubject} from '@visx/annotation';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {GridRows} from '@visx/grid';
import {Group} from '@visx/group';
import {scaleBand, scaleLinear} from '@visx/scale';
import * as Shape from '@visx/shape';
import * as Stats from '@visx/stats';
import * as globals from '@wandb/weave/common/css/globals.styles';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import {
  axisTickRotate,
  getAngledXAxisMarginHeight,
} from '../../../util/plotHelpers/axis';
import {getAxisStyleForFontSize} from '../../../util/plotHelpers/plotFontSize';
import {getPlotMargin} from '../../../util/plotHelpers/plotHelpers';
import {BarChartVizProps} from './types';
import {numTicksForHeight, numTicksForWidth} from './utils';

export const VerticalBarChart = (
  props: BarChartVizProps & {width: number; height: number}
) => {
  const {
    bars,
    width,
    height,
    mouseOver,
    mouseOut,
    boxPlot,
    violinPlot,
    enableAxisTicks = true,
    enableGridRows = false,
    tickLabelSize = 8,
    rotateTickLabel = true,
    showTooltipWhenHoveringAboveBars = false,
    tooltipOnTop = false,
    verticalThresholdLine,
    valueFormatter,
  } = props;

  const yDomain = [
    props.min ?? 0,
    props.max ?? Math.max(...bars.map(d => (isNaN(d.value) ? 0 : d.value))),
  ];

  const xKeys = bars.map(d => d.key);
  const margin = getPlotMargin({
    axisDomain: {yAxis: yDomain},
    axisType: {
      yAxis: 'linear',
    },
    fontSize: props.fontSize ?? 'small',
    yAxisTickFormatter: valueFormatter,
  });

  const axisFontStyles = getAxisStyleForFontSize(props.fontSize ?? 'small');

  const xMargin = getAngledXAxisMarginHeight(xKeys);
  margin.bottom = xMargin;

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = scaleBand({
    range: [0, xMax],
    round: true,
    domain: bars.map((d, i) => i + 1),
    padding: 0.25,
  });
  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: yDomain,
  });
  const barWidth = Math.max(0, xScale.bandwidth());
  return (
    <svg style={{width: '100%', height: '100%'}}>
      <Group top={margin.top} left={margin.left} key="chart">
        {enableGridRows && <GridRows scale={yScale} width={width} />}
        {bars.map((d, i) => {
          const constrainedWidth = Math.min(40, barWidth);

          const barX = xScale(i + 1) ?? 0;
          const key = d.key + i.toString();
          if (violinPlot) {
            return (
              <Stats.ViolinPlot
                key={key}
                data={d.bins ?? []}
                count={data => data.count}
                value={data => data.bin}
                left={barX + barWidth / 2 - constrainedWidth / 2}
                width={constrainedWidth}
                valueScale={yScale}
                fill={d.color ?? 'red'}
                fillOpacity={0.5}
                stroke={d.color ?? 'red'}
                strokeWidth={1}
              />
            );
          } else if (boxPlot) {
            return (
              <Stats.BoxPlot
                key={key}
                min={d.quartiles != null ? d.quartiles[0] : d.value}
                max={d.quartiles != null ? d.quartiles[4] : d.value}
                median={d.quartiles != null ? d.quartiles[2] : d.value}
                firstQuartile={d.quartiles != null ? d.quartiles[1] : d.value}
                thirdQuartile={d.quartiles != null ? d.quartiles[3] : d.value}
                left={barX + barWidth / 2 - constrainedWidth / 2}
                fill={d.color ?? 'red'}
                fillOpacity={0.5}
                stroke={d.color ?? 'red'}
                strokeWidth={1}
                valueScale={yScale}
                boxWidth={constrainedWidth}
                boxProps={{
                  onMouseOver: event => mouseOver && mouseOver(event, d),
                  onMouseLeave: event => mouseOut && mouseOut(),
                }}
              />
            );
          } else {
            const min = Math.min(yDomain[0], yDomain[1]);
            if (d.value < min) {
              return null;
            }

            // barY is where the bar starts, either the origin or the minimum y value
            let barY = Math.max(0, Math.min(yScale(0), yScale(yDomain[0])));
            let barHeight = yScale(d.value) - barY;

            if (isNaN(barHeight)) {
              barHeight = 0;
            }
            if (barHeight < 0) {
              barHeight = Math.abs(barHeight);
              barY = barY - barHeight;
              if (barY < 0) {
                barHeight = Math.max(barHeight + barY, 0);
                barY = 0;
              }
            }
            const onMouseOver = (
              event: React.MouseEvent<SVGRectElement, MouseEvent>
            ) => {
              if (mouseOver) {
                mouseOver(
                  event,
                  d,
                  tooltipOnTop
                    ? // See https://github.com/airbnb/visx/issues/287#issuecomment-386342722 -
                      // without the -32 it appears below the top of the bar, subtracting 32
                      // (the height of the tooltip) makes it appear on top of the bar
                      yMax - barHeight - margin.top - 32
                    : undefined,
                  tooltipOnTop ? barX : undefined
                );
              }
            };

            return (
              <Group key={key}>
                {showTooltipWhenHoveringAboveBars && (
                  // invisible bar that allows you to hover above the bars to still see the tooltip
                  // this is useful for when one bar is very small compared to the others and doesn't have
                  // a big enough space to hover.
                  <Shape.Bar
                    key={key}
                    width={barWidth}
                    height="100%"
                    x={barX}
                    y={0}
                    fillOpacity={0}
                    onMouseOver={onMouseOver}
                    onMouseOut={event => mouseOut && mouseOut()}
                  />
                )}
                <Shape.Bar
                  key={key}
                  width={barWidth}
                  height={barHeight}
                  x={barX}
                  y={barY}
                  fill={d.color ?? 'red'}
                  onMouseOver={onMouseOver}
                  onMouseOut={event => mouseOut && mouseOut()}
                />
                {d.range != null &&
                  _.isFinite(d.range[0]) &&
                  _.isFinite(d.range[1]) && (
                    <Shape.Bar
                      key={key + 'error-bar'}
                      height={Math.max(
                        0,
                        yScale(d.range[0]) - yScale(d.range[1])
                      )}
                      width={1}
                      y={yScale(d.range[1])}
                      x={(barX ?? 0) + barWidth / 2}
                      fill={'black'}
                    />
                  )}
              </Group>
            );
          }
        })}
        {yDomain[0] <= 0 && (
          // origin line
          <Shape.Line
            x1={0}
            y1={yScale(0)}
            x2={xMax}
            y2={yScale(0)}
            stroke={globals.gray500}
            strokeWidth={1.0}
          />
        )}
        {verticalThresholdLine != null && (
          <Annotation
            x={xScale(verticalThresholdLine.xIndex)}
            y={height / 2}
            dx={-50}
            dy={50}>
            <Label
              backgroundFill="white"
              title={verticalThresholdLine.annotationLabel}
              verticalAnchor="middle"
              horizontalAnchor="end"
              showAnchorLine={false}
            />
            <Connector stroke="#ff7e67" type="elbow" />
            <LineSubject
              orientation="vertical"
              stroke="#ff7e67"
              min={0}
              max={height}
            />
          </Annotation>
        )}
      </Group>
      <Group key="axis">
        <AxisLeft
          top={margin.top}
          left={margin.left}
          scale={yScale}
          numTicks={numTicksForHeight(height)}
          labelProps={{
            ...axisFontStyles,
            textAnchor: 'middle',
          }}
          stroke={globals.gray500}
          tickStroke={enableAxisTicks ? `#b3b3b0` : `transparent`}
          strokeWidth={0.5}
          tickLabelProps={(value: any, index: any) => ({
            ...axisFontStyles,
            textAnchor: 'end',
            dx: '-0.25em',
            dy: '0.25em',
          })}
          tickComponent={({formattedValue, ...tickProps}) => {
            return enableAxisTicks ? (
              <text {...(tickProps as any)}>{formattedValue}</text>
            ) : null;
          }}
          tickFormat={
            valueFormatter != null
              ? value => valueFormatter(value.valueOf())
              : undefined
          }
        />

        <line
          x1={margin.left}
          y1={height - margin.bottom}
          x2={width - margin.right}
          y2={height - margin.bottom}
          stroke={globals.gray500}
          strokeWidth={0.5}
        />

        <AxisBottom
          top={height - margin.bottom}
          left={margin.left}
          scale={xScale}>
          {axis => {
            const tickRotate = axisTickRotate;
            const tickColor = globals.gray500;
            const axisCenter = (axis.axisToPoint.x - axis.axisFromPoint.x) / 2;
            const numTicks = numTicksForWidth(width);
            return (
              <g className="my-custom-bottom-axis">
                {axis.ticks
                  .filter(
                    (tick, i) =>
                      props.showAllLabels ||
                      axis.ticks.length <= numTicks ||
                      i % Math.floor(axis.ticks.length / (numTicks - 1)) ===
                        0 ||
                      i === 0 ||
                      i === axis.ticks.length - 1
                  )
                  .map((tick, i) => {
                    const tickX = tick.to.x;
                    const tickY =
                      tick.to.y + tickLabelSize + (axis.tickLength || 0) - 10;

                    if (!enableAxisTicks) {
                      return null;
                    }
                    return (
                      <Group
                        key={`visx-tick-${tick.value}-${i}`}
                        className={'visx-axis-tick'}>
                        <Shape.Line
                          from={tick.from}
                          to={tick.to}
                          stroke={tickColor}
                        />
                        <text
                          transform={classNames(
                            `translate(${tickX}, ${tickY})`,
                            rotateTickLabel ? `rotate(${tickRotate})` : ''
                          )}
                          fontFamily={axisFontStyles.fontFamily}
                          fontSize={tickLabelSize}
                          textAnchor={rotateTickLabel ? 'end' : 'middle'}
                          fill={'#6b6b76'}>
                          {
                            // Here the formattedValue is acutally the index of the tick
                            // If we just use the text, when two values are the same, the
                            // bars are put on top of each other.
                            bars[
                              parseInt(
                                (tick.formattedValue ?? '0').toString(),
                                10
                              ) - 1
                            ].key
                          }
                        </text>
                      </Group>
                    );
                  })}
                <text
                  textAnchor="middle"
                  transform={`translate(${axisCenter}, 20)`}
                  fontSize="8">
                  {axis.label}
                </text>
              </g>
            );
          }}
        </AxisBottom>
      </Group>
    </svg>
  );
};
