import {updateWith} from 'lodash';

import {
  REPORT_DRAFT_VIEW_TYPE,
  REPORT_VIEW_TYPE,
  ReportViewRef,
} from '../reports/types';
import {Viewer} from '../viewer/types';
import {View, ViewRef} from './types';

export function isOwnView(viewer: Viewer | undefined, view: View): boolean {
  return viewer != null && viewer.id === view.user.id;
}

export function isReportView(viewRef: ViewRef): viewRef is ReportViewRef {
  return (
    viewRef.type === REPORT_VIEW_TYPE || viewRef.type === REPORT_DRAFT_VIEW_TYPE
  );
}

/**
 * Given a dot-deliniated path to a property within Value, return the type of
 * Value or undefined. So, for example:
 *
 * @example
 * const s = {
 *   users: {
 *     '123': {
 *       addresses: [{
 *         house_number_and_street: '500 Pennsylvania Avenue'
 *       }]
 *     }
 *   }
 * } as const
 * type Address = GetType<typeof s, 'users.123.addresses.0'>
 * // Address expands to '{house_number_and_street: "500 Pennsylvania Avenue"}'
 *
 * type NonUser = GetType<typeof s, 'users.456'> // undefined
 */
type GetFieldType<Value, Path> = Path extends `${infer Left}.${infer Right}`
  ? Left extends keyof Value
    ? GetFieldType<Value[Left], Right>
    : undefined
  : Path extends keyof Value
  ? Value[Path]
  : undefined;

type UpdaterFn<Value, Path> = (
  value: GetFieldType<Value, Path>
) => GetFieldType<Value, Path>;

/**
 * Given a value, a dot-delinated path, and an update function, return a new
 * value that has the updater change applied to the value at the path. Attempts
 * to smartly structurally share data along the way. For arrays, continue to
 * use dot syntax (e.g. `someList.3.property`) rather than subscript notation.
 *
 * @param value
 * @param path
 * @param updater
 */
export function updateIn<Value extends object | [], Path extends string>(
  value: Value,
  path: Path,
  updater: UpdaterFn<Value, Path>
): Value {
  function customizer<T = unknown>(v: T): T {
    if (Array.isArray(v)) {
      return [...v] as unknown as T;
    } else if (typeof v === 'object' && v !== null) {
      return {...v};
    }
    return v;
  }

  return updateWith<Value>(customizer(value), path, updater, customizer);
}
