// process.env vars (PUBLIC_URL, NODE_ENV, and anything starting with REACT_APP_) are injected
// by create-react-app at the time the frontend is built. Because we need different vars for
// different environments, this means we have to build the frontend multiple times, which is
// incredibly time-consuming. This file, along with inject-index-env.sh, serves as a workaround.

// inject-index-env generates a short js file that sets window.CONFIG based on the env
// vars. Then we load that file in index.html, before any other code runs. Then in this
// file, the `config` var will be populated from window.CONFIG. That way, we can do the
// frontend build once and quickly generate a version for each environment.

// PUBLIC_URL is a special case, because create-react-app injects it into the bundle at
// many places to identify paths to static assets. to deal with this, we always set PUBLIC_URL
// to a placeholder value, and then replace all instances of it with find/sed (this also
// happens in inject-index.env.sh)
//
// To add a new value to this config:
// 1. Add it to Config below
// 2. Add it to the defaults value below
// 3. If you want it in the various environments (production, qa, devprod, etc), add it to the appropriate .env file
// 4. add it to generate-env-js.bash
//
// You can then pass it in to governor by running gorilla like so:
// REACT_APP_{YOUR_NEW_CONFIG_NAME}=true governor start invoker_fe_prod.ini

import {setConfig as setWeaveConfig} from '@wandb/weave/config';

import {keyExistsInQueryString} from './util/query-string';

// Config interface
interface Config {
  AUTH_STUB_JWT: string;
  BACKEND_HOST: string; // NOTE: Don't use this directly. Call backendHost() below.
  ALTERNATE_BACKEND_HOST: string;
  ALTERNATE_BACKEND_PORT: string;
  ANALYTICS_DISABLED: boolean; // used to disable collection of third-party analytics data
  CI: boolean;
  ENABLE_DEBUG_FEATURES: boolean;
  ENVIRONMENT_IS_PRIVATE: boolean;
  ENVIRONMENT_NAME: string;
  HOST: string;
  SENTRY_DSN?: string;
  SENTRY_ENVIRONMENT?: string;
  GITHUB_CLIENT_ID?: string;
  STRIPE_API_KEY?: string;
  MAX_BYTES: number;
  MAX_COMPUTE_HOURS_IN_SECONDS: number;
  MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS: number;
  GIT_TAG: string;
  PUBLIC_URL: string; // NOTE: Don't use this directly. Call urlPrefixed() below.
  WEAVE_BACKEND_HOST: string; // NOTE: Don't use this directly. Call backendWeaveHost() below.
  TRACE_BACKEND_BASE_URL: string; // NOTE: Don't use this directly. Call backendTraceBaseUrl() below.
  DISABLE_TELEMETRY: boolean; // used to disable collection of local analytics data
  LOCAL_TEST_FIXTURE_SERVER_HOST?: string;
  FE_PERF_LOGGING: boolean;
  DATADOG_DEBUG: boolean;
  ENABLE_SECRET_STORE: boolean;
  // We use any type since it comes from the commandline and can be anything, so using any to force
  // our code to validate what it is before using it. If you are creating values for these, you should
  // always pass in strings.
  SERVER_FLAGS: Record<string, any>;
  BANNERS: Record<string, Record<string, any>>;
  ENABLE_REGISTRY_UI: boolean;
  ENABLE_PROJECT_SPECIFIC_ROLES_UI: boolean;
}

declare global {
  interface Window {
    CONFIG?: Config;
  }
}

const defaults = {
  AUTH_STUB_JWT: '',
  BACKEND_HOST: '',
  ALTERNATE_BACKEND_HOST: '',
  ALTERNATE_BACKEND_PORT: '',
  ANALYTICS_DISABLED: true,
  ENABLE_DEBUG_FEATURES: false,
  ENVIRONMENT_IS_PRIVATE: false,
  CI: false,
  ENVIRONMENT_NAME: '',
  HOST: '',
  SENTRY_DSN: '',
  SENTRY_ENVIRONMENT: '',
  GITHUB_CLIENT_ID: '',
  STRIPE_API_KEY: '',
  MAX_BYTES: 100000000000, // TODO: remove this once billing for storage is hooked up
  MAX_COMPUTE_HOURS_IN_SECONDS: 900000, // 250 * 60 * 60
  MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS: 18000000, // 5000 * 60 * 60
  GIT_TAG: '',
  PUBLIC_URL: '',
  WEAVE_BACKEND_HOST: '',
  TRACE_BACKEND_BASE_URL: '',
  DISABLE_TELEMETRY: false,
  LOCAL_TEST_FIXTURE_SERVER_HOST: undefined,
  FE_PERF_LOGGING: false,
  DATADOG_DEBUG: false,
  ENABLE_SECRET_STORE: false,
  SERVER_FLAGS: {},
  BANNERS: {},
  ENABLE_REGISTRY_UI: false,
  ENABLE_PROJECT_SPECIFIC_ROLES_UI: false,
};
const config: Config = Object.assign(
  defaults,
  (typeof window !== 'undefined' && window.CONFIG) ?? {}
);

export default config;

export const envIsProd = config.ENVIRONMENT_NAME === 'production';
export const envIsDev =
  config.ENVIRONMENT_NAME === 'development' ||
  config.ENVIRONMENT_NAME === 'devprod';

// When running tests manually using `jest`,
// we are not properly propagating the environment variables so `envIsTest` is not set.
// `globalThis.process?.env?.NODE_ENV` is set by `jest` as `'test'` when running tests.
// Note that we MUST use the conditional accesses (`?.`) here, or the app will crash
// when trying to read `window.process.env.NODE_ENV`.
// TODO: We can just use `envIsTest` if we fix the environment propagation issue.
export const envIsTest =
  config.ENVIRONMENT_NAME === 'test' ||
  globalThis.process?.env?.NODE_ENV === `test`;

export const envIsIntegration =
  config.ENVIRONMENT_NAME === 'integration' || config.CI;
export const envIsPublicCloud = !config.ENVIRONMENT_IS_PRIVATE;
export const envIsLocal = envIsLocalComputed();

// To make testing simpler, we use this helper function to allow config modification
function envIsLocalComputed() {
  return config.ENVIRONMENT_IS_PRIVATE && config.ENVIRONMENT_NAME === 'local';
}

export const urlPrefixed = (path?: string, host: boolean = true) => {
  // Ensures urls are fully qualified and properly formed, especially important
  // for allowing users to run the app under a sub-path, i.e. https://mycompany.com/wandb.
  // This should be used anytime we're calling wandb.open, window.location.href, or fetch(...).
  // We have a custom eslint rule wandb/no-unprefixed-urls that will assert we use this helper.
  let url = new URL(window.location.origin);
  // Never use a prefix if we're not in local
  if (envIsLocalComputed()) {
    try {
      url = config.PUBLIC_URL.startsWith('http')
        ? new URL(config.PUBLIC_URL)
        : new URL(config.PUBLIC_URL, url.href);
      url.hostname = window.location.hostname;
    } catch (error) {
      console.error(error);
    }
  }

  if (path != null) {
    // This logic does the magic joining of a sub-path and path in the app.
    url = new URL(url.href.replace(/\/$/, '') + path);
  } else if (!url.href.endsWith('/')) {
    // Some routers require a trailing / at the root
    url = new URL(url.href + '/');
  }
  if (!host) {
    return url.pathname;
  }
  return url.href;
};

export const backendHost = (path?: string) => {
  // Check for alternate hostname in origin.
  const alternateHostBase = config.ALTERNATE_BACKEND_HOST.replace(
    'https://api.',
    ''
  );
  if (
    alternateHostBase !== '' &&
    window.location.origin.indexOf(alternateHostBase) !== -1
  ) {
    // Yeah I know this is insane. It's really just a single use case thing for one customer.
    // They have two domain names pointing to one instance, and the second one needs a custom port too.
    let host = config.ALTERNATE_BACKEND_HOST;
    if (config.ALTERNATE_BACKEND_PORT) {
      host = `${host}:${config.ALTERNATE_BACKEND_PORT}`;
    }

    return host;
  }

  // Support for sub-path mounting
  if (config.BACKEND_HOST === '' && config.PUBLIC_URL !== '') {
    // The backendHost should never have a trailing slash, unlike urlPrefixed
    return urlPrefixed(path).replace(/\/$/, '');
  }

  return config.BACKEND_HOST + (path ?? '');
};

// Defining this now so we can corral all access to this value from the start.
export const backendWeaveExecutionUrl = (shadow: boolean = false) => {
  if (shadow) {
    return config.WEAVE_BACKEND_HOST + '/shadow_execute';
  }
  return config.WEAVE_BACKEND_HOST + '/execute';
};

export const backendWeaveOpsUrl = () => {
  return config.WEAVE_BACKEND_HOST + '/ops';
};

export const backendWeaveViewerUrl = () => {
  return config.WEAVE_BACKEND_HOST + '/wb_viewer';
};

export const backendWeaveHost = () => {
  return config.WEAVE_BACKEND_HOST;
};

export const backendTraceBaseUrl = () => {
  return config.TRACE_BACKEND_BASE_URL;
};

export const hasTraceBackend = backendTraceBaseUrl() !== '';

export const getServerFlagInt = (
  flagName: string,
  defaultVal: number,
  min: number,
  max: number
) => {
  const flagVal = config.SERVER_FLAGS?.[flagName] ?? defaultVal.toString();
  if (typeof flagVal !== 'string') {
    return defaultVal;
  }
  const parsedVal = parseInt(flagVal, 10);
  if (isNaN(parsedVal) || parsedVal < min || parsedVal > max) {
    return defaultVal;
  }
  return parsedVal;
};

export const serverWeaveShadowPercentage = () =>
  getServerFlagInt('WEAVE_SHADOW_PERCENTAGE', 0, 0, 100);

export const serverWeave1Percentage = () =>
  getServerFlagInt('WEAVE_1_PERCENTAGE', 0, 0, 100);

export const frontendPerfLoggingEnabled = () => {
  return config.FE_PERF_LOGGING || keyExistsInQueryString('PERF_LOGGING');
};

export const datadogDebugOverride = () => {
  return config.DATADOG_DEBUG;
};

setWeaveConfig({
  urlPrefixed,
  backendWeaveExecutionUrl,
  backendWeaveViewerUrl,
  backendWeaveOpsUrl,
  ENABLE_DEBUG_FEATURES: config.ENABLE_DEBUG_FEATURES,
});
