import {isEqual} from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {VizMap} from '../../state/graphql/historyKeysQuery';
import {useKeyInfoQuery} from '../../state/runs/hooks';
import * as RunSetTypes from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';
import * as RunHelpers from '../../util/runhelpers';

export type KeyInfoQueryResult = {
  loading: boolean;
  error: true | null;
  historyKeyInfo: RunHistoryKeyInfo;
  viz: VizMap;
};

type KeyInfoQueryContext = {
  keyInfoQuery: KeyInfoQueryResult;
  uniqueKeyTypes?: Set<string>;
  getSortedHistoryKeys: () => void; // This is currently used in runs line plot (useRegexMetrics)
};
const KeyInfoQueryContext = createContext<KeyInfoQueryContext | undefined>(
  undefined
);

export const KeyInfoQueryContextProvider = ({
  children,
  runSetRefs,
}: {
  children: React.ReactNode;
  runSetRefs: RunSetTypes.Ref[];
}) => {
  const keyInfoQueryResult = useKeyInfoQueryResult(runSetRefs);
  const uniqueKeyTypes = useMemo(() => {
    return makeUniqueKeyTypes(keyInfoQueryResult);
  }, [keyInfoQueryResult]);

  const {historyKeyInfo} = keyInfoQueryResult;

  /**
   * Sorting keys (that might number in the 10k+ range) can get expensive.
   * We should only calculate when needed and update when keys change.
   */
  const [sortedHistoryKeys, setSortedHistoryKeys] = useState<
    string[] | undefined
  >();
  const getSortedHistoryKeys = useCallback(() => {
    if (sortedHistoryKeys == null) {
      const newKeys = Object.keys(historyKeyInfo.keys).sort();
      setSortedHistoryKeys(newKeys);
      return newKeys;
    }
    return sortedHistoryKeys;
  }, [historyKeyInfo.keys, sortedHistoryKeys]);

  useEffect(() => {
    // Make sure to update sortedKeys when historyKeyInfo keys change
    if (sortedHistoryKeys != null) {
      const newKeys = Object.keys(historyKeyInfo.keys).sort();
      if (!isEqual(newKeys, sortedHistoryKeys)) {
        setSortedHistoryKeys(newKeys);
      }
    }
  }, [historyKeyInfo, sortedHistoryKeys]);

  const value = useMemo(
    () => ({
      keyInfoQuery: keyInfoQueryResult,
      uniqueKeyTypes,
      getSortedHistoryKeys,
    }),
    [getSortedHistoryKeys, keyInfoQueryResult, uniqueKeyTypes]
  );

  return (
    <KeyInfoQueryContext.Provider value={value}>
      {children}
    </KeyInfoQueryContext.Provider>
  );
};
KeyInfoQueryContextProvider.displayName = 'KeyInfoQueryContextProvider';

export const useKeyInfoQueryContext = () => {
  const context = useContext(KeyInfoQueryContext);

  if (!context) {
    throw new Error(
      'useKeyInfoQueryContext must be used within a KeyInfoQueryContextProvider'
    );
  }

  return context;
};

function makeUniqueKeyTypes(
  keyInfoQuery: KeyInfoQueryResult
): Set<string> | undefined {
  if (keyInfoQuery.loading || keyInfoQuery.error != null) {
    return undefined;
  }

  const {historyKeyInfo} = keyInfoQuery;
  if (historyKeyInfo == null) {
    return undefined;
  }

  const keyTypes = RunHelpers.keyTypes(historyKeyInfo.keys);
  if (keyTypes == null) {
    return undefined;
  }
  return new Set<string>(Object.values(keyTypes) as string[]);
}

const useKeyInfoQueryResult = (
  runSetRefs: RunSetTypes.Ref[]
): KeyInfoQueryResult => {
  const keyInfoQuery = useKeyInfoQuery(runSetRefs);

  const isKeyInfoLoading = keyInfoQuery.loading;
  const keyInfoQueryError = keyInfoQuery.error;

  const historyKeyInfo = useMemo(() => {
    if (isKeyInfoLoading || keyInfoQueryError != null) {
      return {sets: [], keys: {}, sortedKeys: []};
    }
    return keyInfoQuery.historyKeyInfo;
  }, [isKeyInfoLoading, keyInfoQuery, keyInfoQueryError]);

  const viz = useMemo(() => {
    if (isKeyInfoLoading || keyInfoQueryError != null) {
      return {};
    }
    return keyInfoQuery.viz;
  }, [isKeyInfoLoading, keyInfoQuery, keyInfoQueryError]);

  return useMemo(() => {
    return {
      loading: isKeyInfoLoading,
      error: keyInfoQueryError,
      historyKeyInfo,
      viz,
    };
  }, [historyKeyInfo, viz, isKeyInfoLoading, keyInfoQueryError]);
};
