/* Semantic UI helpers */
import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {
  fuzzyMatchHighlight,
  fuzzyMatchWithMapping,
} from '@wandb/weave/common/util/fuzzyMatch';
import {Option} from '@wandb/weave/common/util/uihelpers';
import * as _ from 'lodash';
import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {DropdownItemProps, Icon} from 'semantic-ui-react';

import {keyDisplayName, keyFromString, keyStringDisplayName} from './runs';
import {Key} from './runTypes';

export * from '@wandb/weave/common/util/uihelpers';

const KEY_LIMIT = 100;

export const UI_TAG_TEXT = 'tag';

export interface DropdownOption {
  key: string;
  text: string;
  value: string;
}

export const numberWithCommas = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const maybePluralizeWord = (count: number, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;

export const maybePluralize = (
  count: number,
  noun: string,
  suffix = 's',
  shouldAddCommas = false
) =>
  `${shouldAddCommas ? numberWithCommas(count) : count} ${maybePluralizeWord(
    count,
    noun,
    suffix
  )}`;

// Take a dropdown option and beautifies it.
// Adding an image and casting the text
export function beautify(item: Option): Option {
  const key = keyFromString(item.key);
  const image = key ? getImage(key) : <Icon name="tasks" />;
  const text = key ? keyDisplayName(key, true) : item.key;

  return {
    ...item,
    text: image ? (
      <span className="dropdown-with-icon">
        {image} {text}
      </span>
    ) : (
      text
    ),
  };
}

export const searchFunction = (options: DropdownItemProps[], query: string) => {
  const matched = fuzzyMatchWithMapping(options, query, o =>
    o.key.toString()
  ).slice(0, KEY_LIMIT);
  return matched.map(o => {
    const key = keyFromString(o.key);
    return beautify({
      key,
      value: key ?? o.key,
      ...o,
      text:
        key != null ? fuzzyMatchHighlight(keyDisplayName(key), query) : o.key,
    });
  });
};

export const getImage = (key: Key) => {
  return <LegacyWBIcon name={getIconNameForKey(key)} />;
};

export const getIconNameForKey = (key: Key) => {
  if (key.section === 'run') {
    switch (key.name) {
      case 'createdAt':
        return 'date';
      case 'heartbeatAt':
        return 'date';
      case 'updatedAt':
        return 'date';
      case 'duration':
        return 'duration';
      case 'name':
        return 'metadata';
      case 'status':
        return 'state';
      case 'username':
        return 'username';
      default:
        return 'metadata';
    }
  }

  switch (key.section) {
    case 'config':
      return 'configuration';
    case 'summary':
      return 'summary';
    case 'tags':
      return 'tag-gray';
    default:
      return 'summary';
  }
};

export const isTag = (v: string) => {
  return v.split(':')[0] === 'tags';
};

export function partitionTags(values: string[]): [string[], string[]] {
  return _.partition<string>(values, isTag);
}

export function tagOption(v: string): Option {
  return beautify({
    text: UI_TAG_TEXT,
    key: v,
    value: v,
  });
}

export function makeOptions(values: string[]) {
  return values.map(v => ({text: v, key: v, value: v}));
}

export function makeOptionsFromKeyStrings(values: string[]) {
  return makeOptions(values).map(option => ({
    ...option,
    text: keyStringDisplayName(option.text),
  }));
}

// e.persist() is necessary to use setTimeout in an event handler (like with _.debounce or _.throttle)
// see Event Pooling here: https://reactjs.org/docs/events.html
export function handleAsyncEvent(
  e: React.ChangeEvent<HTMLInputElement>,
  // tslint:disable-next-line:ban-types
  eventHandler: Function
) {
  e.persist();
  eventHandler(e);
}
