import {omit} from 'lodash';

import {
  PanelBankSectionConfig,
  PanelBankSectionConfigWithVisiblePanels,
} from '../../components/PanelBank/types';
import {InheritedSettings} from '../../components/WorkspaceDrawer/Settings/types';
import {getCascadingPanelSettings} from '../../components/WorkspaceDrawer/Settings/useCascadingSettings';
import {Config, LayedOutPanel} from '../panelTypes';
import {panelBankSectionFromJSON, upgradeToPanelBank} from '../parseSpecUtils';
import {ReportSpecVersion} from './shared';

// This is run in the loadFinished reducer, for reports.
// (A report section's panels = a single PanelBankSection)
// It returns a single PanelBankSection
// Put all migrations to the PanelBankSectionConfig object here.
export const migrateReportToPanelBank = (
  viewType: 'runs' | 'runs/draft',
  existingPanelBankSectionConfig: PanelBankSectionConfig,
  legacyConfig: Config | null,
  specVersion: ReportSpecVersion
): PanelBankSectionConfig => {
  const panelBankSectionConfig =
    specVersion === ReportSpecVersion.V0 && legacyConfig != null
      ? (upgradeToPanelBank(legacyConfig, true) as PanelBankSectionConfig)
      : existingPanelBankSectionConfig; // no-op

  return panelBankSectionFromJSON(panelBankSectionConfig, viewType);
};

/**
 * When we create a report from the workspace pages, we want to apply
 * cascading settings hierarchy to the line plot panels.
 * panel settings >> section settings >> workspace settings
 */
export function getReportPanel(
  p: LayedOutPanel,
  inheritedSettings: InheritedSettings | undefined,
  section: PanelBankSectionConfigWithVisiblePanels
) {
  if (p.viewType !== 'Run History Line Plot') {
    return p;
  }

  // Determine if workspace or section settings should take precedence
  const parentPanelSettings = getCascadingPanelSettings(
    inheritedSettings,
    section?.sectionSettings?.linePlot
  );

  return {
    ...p,
    config: {
      ...inheritedSettings,
      ...omit(section?.sectionSettings, 'linePlot'),
      ...p.config,
      ...getCascadingPanelSettings(parentPanelSettings, undefined, p.config),
    },
  };
}
