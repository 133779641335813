import {original} from 'immer';

import {
  AddPanel,
  PanelBankDiff,
  SectionPanelSorting,
  UpdatePanel,
} from '../../../components/PanelBank/types';
import {
  addMetricsToPanelConfig,
  getDefaultPanelConfig,
  updateDefaultxAxis,
} from '../../../util/panelbank';
import {getDefaultPanelSectionConfig} from '../../../util/panelbankConfigs';
import {
  PANEL_BANK_TABLES_NAME,
  TABLE_SECTION_HEIGHT,
} from '../../../util/panelbankTypes';
import {LayedOutPanel} from '../../../util/panelTypes';
import * as Normalize from '../normalize';
import {StateType} from '../normalizerSupport';
import * as PanelTypes from '../panel/types';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import {insertPanelsAlphabetically} from '../panelBankSectionConfig/utils';
import * as Types from './types';

interface SectionInfo {
  keyType: string; // this is needed if the section has to be created
  panelRefs: PanelTypes.Ref[];
}

export const addOrUpdatePanelsToSections = (
  panelBankDiff: PanelBankDiff,
  reducerStateParts: StateType,
  panelBankConfigRef: Types.Ref
) => {
  const seenInDiff = new Set();

  // Optimization: since new panels are placed at the beginning of the section
  // (for non-alphabetized sections), we create the full list of metrics
  // to add (grouped by section name in this map), then add all the
  // metrics for each section in one go.
  //
  // If you change this function or its children, be aware it can be
  // run with up to 100k of metrics to add to a section or sections.
  const newPanelsBySection = new Map<
    string, // section name
    SectionInfo
  >();

  const panelsById = new Map<string, LayedOutPanel>();
  Object.values(reducerStateParts.panel).forEach(panel => {
    panelsById.set(panel.__id__, panel);
  });

  for (const [key, operation] of Object.entries(panelBankDiff)) {
    if (seenInDiff.has(key)) {
      console.error(`Duplicated key in panelBankDiff ${key}`);
    } else {
      seenInDiff.add(key);
    }
    if (operation.type === 'update') {
      // update path has not been analyzed for performance,
      const existingPanel = panelsById.get(operation.panelId);
      if (existingPanel) {
        updatePanel(operation, existingPanel);
      }
    } else if (operation.type === 'add') {
      // this is either a new panel, or a just-deleted panel we need to add
      // to the "Hidden" section
      addNewPanelToSectionInfo(
        operation,
        newPanelsBySection,
        key,
        reducerStateParts,
        panelBankConfigRef
      );
    }
  }

  // Now that we've grouped all the metrics by section,
  // add the metric list to each section
  return addAllSectionInfosToReducerState(
    newPanelsBySection,
    reducerStateParts,
    panelBankConfigRef
  );
};

// this takes the new panels we need to add (grouped by section)
// and adds all the panels to each section
function addAllSectionInfosToReducerState(
  newPanelsBySection: Map<string, SectionInfo>,
  reducerStateParts: StateType,
  panelBankConfigRef: Types.Ref
): Types.UndoPanelAutoGenInfo {
  if (!newPanelsBySection.size) {
    return;
  }

  const originalParts = original(reducerStateParts) as StateType;
  const prevSectionRefs =
    originalParts[panelBankConfigRef.type][panelBankConfigRef.id].sectionRefs;

  // create map of section refs for quick lookup
  const sectionRefsMap = new Map<string, PanelBankSectionConfigTypes.Ref>();
  prevSectionRefs.forEach(sectionRef => {
    const sectionName = originalParts[sectionRef.type][sectionRef.id].name;
    sectionRefsMap.set(sectionName.toUpperCase(), sectionRef);
  });

  const updatedSections = [];

  for (const [sectionName, sectionInfo] of newPanelsBySection) {
    let targetSectionRef = sectionRefsMap.get(sectionName.toUpperCase());

    if (!targetSectionRef) {
      targetSectionRef = createNewSection(
        sectionName,
        sectionInfo,
        reducerStateParts,
        panelBankConfigRef
      );
    } else {
      updatedSections.push({
        sectionRef: targetSectionRef,
        prevPanelRefs:
          originalParts[targetSectionRef.type][targetSectionRef.id].panelRefs,
      });
    }

    // now that we're guaranteed to have a ref for the target section,
    // we can resolve it:
    const targetSection =
      reducerStateParts['panel-bank-section-config'][targetSectionRef.id];

    if (targetSection.sorted === SectionPanelSorting.Alphabetical) {
      insertPanelsAlphabetically(
        reducerStateParts,
        targetSection,
        sectionInfo.panelRefs
      );
    } else {
      targetSection.panelRefs = [
        // using reverse to maintain "newest first" ordering - might not be necessary,
        // but matching existing behavior exactly.
        ...sectionInfo.panelRefs.reverse(),
        ...targetSection.panelRefs,
      ];
    }
  }
  return {
    prevSectionRefs,
    updatedSections,
  };
}

// create the LayedOutPanel and ensures that we have the correct
// SectionInfo, and adds the panel to SectionInfo so we can add it to a section later.
function addNewPanelToSectionInfo(
  operation: AddPanel,
  sectionNewPanels: Map<string, SectionInfo>,
  key: string,
  reducerStateParts: StateType,
  panelBankConfigRef: Types.Ref
) {
  const targetSectionName = operation.spec.defaultSection;
  let sectionInfo = sectionNewPanels.get(targetSectionName);
  if (sectionInfo == null) {
    sectionInfo = {keyType: operation.spec.keyType ?? '', panelRefs: []};
    sectionNewPanels.set(targetSectionName, sectionInfo);
  }
  const panel = getDefaultPanelConfig(key, operation.spec);
  const panelRef = Normalize.addObj(
    reducerStateParts,
    'panel',
    panelBankConfigRef.viewID,
    panel
  );
  sectionInfo.panelRefs.push(panelRef);
}

// create a new PanelSectionConfig
function createNewSection(
  sectionName: string,
  sectionInfo: SectionInfo,
  reducerStateParts: StateType,
  ref: Types.Ref
) {
  const defaultConfig = getDefaultPanelSectionConfig({
    name: sectionName,
  });
  if (
    sectionName === PANEL_BANK_TABLES_NAME &&
    [
      'table-file',
      'partitioned-table',
      'joined-table',
      'wb_trace_tree',
    ].includes(sectionInfo.keyType)
  ) {
    defaultConfig.flowConfig.boxHeight = TABLE_SECTION_HEIGHT;
  }
  const targetSectionRef = Normalize.addObj(
    reducerStateParts,
    'panel-bank-section-config',
    ref.viewID,
    defaultConfig
  );

  const sectionRefs =
    reducerStateParts['panel-bank-config'][ref.id].sectionRefs;
  const firstSection =
    reducerStateParts['panel-bank-section-config'][sectionRefs[0]?.id];
  if (sectionName === 'System') {
    // "System" section goes towards the bottom, above only "Hidden"
    reducerStateParts['panel-bank-config'][ref.id].sectionRefs.splice(
      sectionRefs.length - 1,
      0,
      targetSectionRef
    );
  } else if (firstSection.name === 'Sweep') {
    // "Sweep" section should remain at the top; insert at index 1
    reducerStateParts['panel-bank-config'][ref.id].sectionRefs.splice(
      1,
      0,
      targetSectionRef
    );
  } else {
    // Otherwise just add new section to the top -- in this case `concat`
    // is more performant than `splice` or `unshift`. This matters because
    // some projects could have tooooons of sections (like 25k)
    reducerStateParts['panel-bank-config'][ref.id].sectionRefs = [
      targetSectionRef,
    ].concat(sectionRefs);
  }

  return targetSectionRef;
}

// updates the config for a particular panel
function updatePanel(operation: UpdatePanel, existingPanel: LayedOutPanel) {
  if (
    operation.spec.type === 'legacy-vega' ||
    operation.spec.type === 'api-added-panel'
  ) {
    if (operation.spec.type === 'legacy-vega') {
      existingPanel.viewType = 'Vega';
    } else {
      existingPanel.viewType = operation.spec.viewType;
    }
    existingPanel.config = operation.spec.config;
  } else if (
    existingPanel.viewType === 'Run History Line Plot' &&
    operation.spec.type === 'default-panel'
  ) {
    addMetricsToPanelConfig(existingPanel, operation.spec.metrics);
    updateDefaultxAxis(existingPanel, operation.spec.defaultXAxis);
  }
}
