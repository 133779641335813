import React, {createContext, useContext, useMemo} from 'react';

import {useViewAction} from '../../../state/views/hooks';
import * as PanelBankSectionConfigActions from '../../../state/views/panelBankSectionConfig/actions';
import {useSectionSettings} from '../../../state/views/panelBankSectionConfig/selectors';
import {Ref as SectionRef} from '../../../state/views/panelBankSectionConfig/types';
import {DispatchableAction} from '../../../types/redux';
import {getLinePlotSettings} from './getLinePlotSettings';
import {LinePlotSettings} from './types';

export type SectionSettingsContextType = {
  sectionRef: SectionRef;
  linePlot: LinePlotSettings;
  updateLinePlotSectionSettings: (
    settings: Partial<LinePlotSettings>
  ) => DispatchableAction;
};

export const SectionSettingsContext = createContext<
  SectionSettingsContextType | undefined
>(undefined);

type SectionSettingsProps = {
  sectionRef: SectionRef;
  children: React.ReactNode;
};

/**
 * This context provider is used in conjuction with WorkspaceSettingsContextProvider.
 * We use these two context providers to handle cascading settings logic.
 */
export const SectionSettingsContextProvider = ({
  sectionRef,
  children,
}: SectionSettingsProps) => {
  const sectionSettings = useSectionSettings(sectionRef);
  const {ignoreOutliers, xAxis, xAxisMin, xAxisMax} =
    getLinePlotSettings(sectionSettings);

  const updateLinePlotSectionSettings = useViewAction(
    sectionRef,
    PanelBankSectionConfigActions.updateLinePlotSectionSettings
  );

  const value = useMemo(
    () => ({
      sectionRef,
      sectionSettings,
      linePlot: {ignoreOutliers, xAxis, xAxisMin, xAxisMax},
      updateLinePlotSectionSettings,
    }),
    [
      sectionSettings,
      ignoreOutliers,
      sectionRef,
      xAxis,
      xAxisMax,
      xAxisMin,
      updateLinePlotSectionSettings,
    ]
  );

  return (
    <SectionSettingsContext.Provider value={value}>
      {children}
    </SectionSettingsContext.Provider>
  );
};

export const useSectionSettingsContext = (): SectionSettingsContextType => {
  const value = useContext(SectionSettingsContext);

  if (value == null) {
    throw new Error(
      'useSectionSettingsContext must be used within a SectionSettingsContextProvider'
    );
  }

  return value;
};
