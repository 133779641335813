import {addUTCTimezoneIfNotPresent} from '@wandb/weave/common/util/time';

export function parseDate(date: any): Date | null {
  if (typeof date !== 'string') {
    return null;
  }
  const dateObj = new Date(addUTCTimezoneIfNotPresent(date));
  try {
    dateObj.toISOString();
  } catch {
    return null;
  }
  return dateObj;
}
