import {WindowSize} from '@wandb/weave/common/hooks/useWindowSize';
import {ID} from '@wandb/weave/common/util/id';
import {DAY} from '@wandb/weave/common/util/time';
import {isNotNullOrUndefined, Struct} from '@wandb/weave/common/util/types';
import {capitalizeFirst} from '@wandb/weave/core';
import produce from 'immer';
import _ from 'lodash';
import * as queryString from 'query-string';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {matchPath} from 'react-router';

import config, {envIsDev, envIsProd} from '../../config';
import {
  useFeaturedReportsMetadataQuery,
  useGalleryQuery,
} from '../../generated/graphql';
import {GALLERY_PATH, GALLERY_PATH_SEGMENT} from '../../routes/paths';
import {store} from '../../setup';
import {setGalleryTags} from '../../state/global/actions';
import {useDispatch, useSelector} from '../../state/hooks';
import {useViewer} from '../../state/viewer/hooks';
import {Viewer} from '../../state/viewer/types';
import {RootState} from '../../types/redux';
import history from '../history';
import {safeLocalStorage} from '../localStorage';
import {isOnReportView} from '../urls';
import {isOneOf} from '../utility';
import wait from '../wait';
import bizzaboEventData from './fcBizzaboEvents.json';
import projectUsagesData from './fcProjectUsages.json';
import {
  BIZZABO_PATH,
  BizzaboEvent,
  DEFAULT_LANGUAGE,
  DISCUSSION_CATEGORY_ID,
  EMPTY_GALLERY_SPEC,
  FC_PROJECT_USAGE_PATH,
  FCEventMetadata,
  GallerySpec,
  Language,
  POST_CATEGORY_ID,
  ProjectUsage,
  Repo,
  RepoLink,
  ReportIDWithTagIDs,
  ReportMetadata,
  SortOrder,
  sortOrTagToPath,
  Tag,
  TAG_PAGES,
  TagV2,
} from './shared';

export * from './shared';

export const MOBILE_WIDTH = 900;

export const CATEGORIES_SHOWING_DESCRIPTION_SET: Set<string> = new Set([
  'Events',
  'Gradient Dissent',
]);

export const TOP_CONTRIBUTORS_LABEL = 'Top contributors';

const GALLERY_ADMINS = new Set(
  [
    'lavanya@wandb.com',
    'aman@wandb.com',
    'morg@wandb.com',
    'andrea@wandb.com',
    'scott@wandb.com',
    'carey@wandb.com',
    'ddavies@wandb.com',
    'justin.tenuto@wandb.com',
    'rajne@wandb.com',
    'anish@wandb.com',
    'soumik.rakshit@wandb.com',
    'gareth.goh@wandb.com',
    'teli.davies@wandb.com',
  ].map(e => e.toLowerCase())
);

type GallerySpecResult = {
  loading: boolean;
  viewID?: string;
  gallerySpec: GallerySpec;
  refetchGallerySpec: () => Promise<GallerySpec>;
};

// HAX: we should definitely be putting this in Redux instead
let gallerySpecCache: Omit<GallerySpecResult, 'loading'> | null = null;
export function useGallerySpecCached(): ReturnType<typeof useGallerySpec> {
  const fetchResult = useGallerySpec({skip: gallerySpecCache == null});
  if (gallerySpecCache != null) {
    return {
      loading: false,
      ...gallerySpecCache,
    };
  }
  return fetchResult;
}

export function useGallerySpec(args?: {skip?: boolean}): GallerySpecResult {
  const skip = args?.skip ?? false;
  const {loading, data, refetch} = useGalleryQuery({skip});

  const viewID = data?.singletonView?.id;

  const gallerySpec: GallerySpec = useMemo(() => {
    const specStr = data?.singletonView?.spec;
    if (specStr == null) {
      return EMPTY_GALLERY_SPEC;
    }
    return JSON.parse(specStr);
  }, [data]);

  const refetchGallerySpec = useCallback(async () => {
    const {data: refetchedData} = await refetch();
    const refetchedSpecStr = refetchedData?.singletonView?.spec;
    const refetchedGallerySpec =
      refetchedSpecStr != null
        ? JSON.parse(refetchedSpecStr)
        : EMPTY_GALLERY_SPEC;
    return refetchedGallerySpec;
  }, [refetch]);

  if (gallerySpec !== EMPTY_GALLERY_SPEC) {
    gallerySpecCache = {
      viewID,
      gallerySpec,
      refetchGallerySpec,
    };
  }

  return {
    loading,
    viewID,
    gallerySpec,
    refetchGallerySpec,
  };
}

type ReportMetadataResult = {
  loading: boolean;
  reportMetadatas: ReportMetadata[] | null;
  fetchedIDs: string[];
};

export function useReportMetadata(viewIDs?: string[]): ReportMetadataResult {
  const {loading: galleryLoading, gallerySpec} = useGallerySpec();

  const galleryViewIDs = useMemo(
    () => gallerySpec.reportIDsWithTagV2IDs.map(r => r.id),
    [gallerySpec]
  );
  const metadataViewIDs = viewIDs ?? galleryViewIDs;

  // The useMemo is required so this doesn't change
  // throughout the lifetime of the component
  const recentStarCountFrom = useMemo(
    () => new Date(Date.now() - 30 * DAY).toISOString(),
    []
  );
  const {
    loading: reportMetadataLoading,
    data: reportMetadataData,
    variables,
  } = useFeaturedReportsMetadataQuery({
    variables: {
      ids: metadataViewIDs,
      recentStarCountFrom,
    },
    skip: metadataViewIDs.length === 0,
  });

  const reportMetadataNodes = useMemo(
    () => reportMetadataData?.views?.edges.map(e => e.node!),
    [reportMetadataData]
  );

  const reportMetadatas: ReportMetadata[] | null = useMemo(() => {
    if (galleryLoading || reportMetadataNodes == null) {
      return null;
    }
    const tagByID: Map<string, Tag> = new Map(
      gallerySpec.tagsV2.map(t => [t.id, t])
    );
    const reportIDWithTagIDByID: Map<string, ReportIDWithTagIDs> = new Map(
      gallerySpec.reportIDsWithTagV2IDs.map(r => [r.id, r])
    );
    return reportMetadataNodes
      .map(n => {
        const reportIDWithTagID = reportIDWithTagIDByID.get(n.id);
        if (reportIDWithTagID == null) {
          return {
            ...n,
            language: DEFAULT_LANGUAGE,
          } as unknown as ReportMetadata;
        }
        const {
          tagIDs,
          primaryTagID,
          onlyShowOnTagIDs,
          authors,
          addedAt,
          language,
          isTranslationOf,
          pending,
          announcement,
          customSchema,
        } = reportIDWithTagID;
        return {
          ...n,
          language,
          isTranslationOf,
          tags: tagIDs
            .map(tid => tagByID.get(tid))
            .filter(isNotNullOrUndefined),
          primaryTagID,
          onlyShowOnTagIDs,
          authors,
          addedAt,
          pending,
          announcement,
          customSchema,
        } as unknown as ReportMetadata;
      })
      .filter(isNotNullOrUndefined);
  }, [galleryLoading, gallerySpec, reportMetadataNodes]);

  const {ids} = variables;
  const fetchedIDs: string[] = typeof ids === 'string' ? [ids] : ids;

  return {
    loading: galleryLoading || reportMetadataLoading,
    reportMetadatas,
    fetchedIDs,
  };
}

type ReportMetadataWithCacheParams = {
  reportIDsWithTagV2IDs: ReportIDWithTagIDs[];
  tags: TagV2[];
  activeTag: string;
  fetchAll: boolean;
  reportBelongsToTag: (reportID: string, tagName: string) => boolean;
};

type ReportMetadataWithCacheResult = {
  loading: boolean;
  reportMetadatas: ReportMetadata[];
  updateReportMetadata: (
    id: string,
    updateFn: (r: ReportMetadata) => void
  ) => void;
};

export function useReportMetadataWithCache({
  reportIDsWithTagV2IDs,
  tags,
  activeTag,
  fetchAll,
  reportBelongsToTag,
}: ReportMetadataWithCacheParams): ReportMetadataWithCacheResult {
  const fetchedReportIDsRef = useRef<Set<string>>(new Set());
  const [cachedReports, setCachedReports] = useState<ReportMetadata[]>([]);

  const newViewIDs = useMemo(() => {
    // filter by active tag
    const tag = tags.find(t => t.name === activeTag);
    const viewIDsWithTagIDs =
      tag != null
        ? reportIDsWithTagV2IDs.filter(r => reportBelongsToTag(r.id, tag.name))
        : reportIDsWithTagV2IDs;
    // omit report IDs that we've tried fetching with
    return viewIDsWithTagIDs
      .filter(v => !fetchedReportIDsRef.current.has(v.id))
      .map(v => v.id);
  }, [tags, activeTag, reportIDsWithTagV2IDs, reportBelongsToTag]);

  const {loading, reportMetadatas, fetchedIDs} = useReportMetadata(
    fetchAll ? undefined : newViewIDs
  );

  const newCachedReports = useMemo(() => {
    return produce(cachedReports, draft => {
      if (reportMetadatas == null) {
        return;
      }
      const cachedIDs = new Set(draft.map(r => r.id));
      const newReports = reportMetadatas.filter(r => !cachedIDs.has(r.id));
      draft.push(...newReports);
    });
  }, [cachedReports, reportMetadatas]);

  // cache IDs that we've used when fetching and new reports
  useEffect(() => {
    if (loading) {
      return;
    }
    fetchedReportIDsRef.current = produce(
      fetchedReportIDsRef.current,
      draft => {
        fetchedIDs.forEach(vid => draft.add(vid));
      }
    );
    setCachedReports(newCachedReports);
  }, [newCachedReports, fetchedIDs, loading]);

  const updateReportMetadata = useCallback(
    (id: string, updateFn: (r: ReportMetadata) => void) => {
      setCachedReports(prev =>
        produce(prev, draft => {
          const reportToUpdate = draft.find(r => r.id === id);
          if (reportToUpdate == null) {
            return;
          }
          updateFn(reportToUpdate);
        })
      );
    },
    []
  );

  return {
    loading,
    reportMetadatas: newCachedReports,
    updateReportMetadata,
  };
}

export function useReportTags(
  viewID: string | null,
  includeHidden = false
): {
  loading: boolean;
  tags: TagV2[];
  primaryTag: TagV2 | null;
} {
  const {
    loading,
    gallerySpec: {reportIDsWithTagV2IDs, tagsV2},
  } = useGallerySpec();

  const [tags, primaryTag] = useMemo(() => {
    const tagList = includeHidden ? tagsV2 : tagsV2.filter(t => !t.hidden);
    const tagByID = new Map(tagList.map(t => [t.id, t]));
    const report = reportIDsWithTagV2IDs.find(({id}) => id === viewID);
    if (report == null) {
      return [[], null];
    }

    const tagsRet = report.tagIDs
      .map(id => tagByID.get(id))
      .filter(isNotNullOrUndefined);
    const primaryTagRet =
      tagsRet.find(t => t.id === report.primaryTagID) ?? null;
    return [tagsRet, primaryTagRet];
  }, [viewID, reportIDsWithTagV2IDs, tagsV2, includeHidden]);

  return {loading, tags, primaryTag};
}

// TODO - needs to be renamed to useFeaturedReport for some clarity
export function useIsGalleryReport(
  viewID: string,
  skip?: boolean
): {
  loading: boolean;
  isGalleryReport: boolean;
} {
  const {loading, gallerySpec} = useGallerySpec({skip});

  // Note: This was updated from reportIDsWithTagIDs after new FC was introduced
  const isGalleryReport = gallerySpec.reportIDsWithTagV2IDs.some(
    ({id}) => id === viewID
  );

  return {loading, isGalleryReport};
}

export function useIsGalleryAdmin(): boolean {
  const viewer = useViewer();
  return viewer != null && GALLERY_ADMINS.has(viewer.email.toLowerCase());
}

export function newTag(): Tag {
  return {
    id: ID(),
    name: '',
    description: '',
    imageURL: '',
    linkForm: '',
    featuredReportIDs: [],
  };
}

export function newRepo(): Repo {
  return {
    id: ID(),
    url: '',
    name: '',
    description: '',
    linkForm: '',
    customContent: '',
    links: [],
    imageURL: '',
  };
}

export function newRepoLink(): RepoLink {
  return {
    id: ID(),
    url: '',
    name: '',
    description: '',
    imageURL: '',
  };
}

export function newTagV2(): TagV2 {
  return {
    id: ID(),
    name: '',
    description: '',
    imageURL: '',
    linkForm: '',
    featuredReportIDs: [],
    featuredReports: [],
    parent: null,
    hidden: false,
  };
}

export function tagFromQS(): string | null {
  const qs = queryString.parse(window.location.search);
  return (qs.galleryTag as string) || null;
}

export function useFormatTag(linkForm: string): string {
  const tags = useGalleryTags();
  return formatGalleryTag(tags, linkForm);
}

export function formatGalleryTag(tags: Tag[], linkForm: string): string {
  for (const t of tags) {
    if (t.linkForm === linkForm) {
      return t.name;
    }
  }

  if (linkForm === 'top-contributors') {
    return TOP_CONTRIBUTORS_LABEL;
  }

  return linkForm.split('-').map(capitalizeFirst).join(' ');
}

export function isOnReportGallery(): boolean {
  return getReportGalleryMatch() != null;
}

export function getReportGalleryMatch(): ReturnType<typeof matchPath> {
  return matchPath(history.location.pathname, {path: GALLERY_PATH});
}

export function getReportGalleryPathParams(tags: Tag[]): {
  sort: string | null;
  tag: string | null;
} | null {
  const match = getReportGalleryMatch();
  if (match == null) {
    return null;
  }
  if (match.params.tag) {
    return {
      sort: match.params.sortOrTag!,
      tag: match.params.tag,
    };
  }
  if (match.params.sortOrTag) {
    if (tagExistsByLinkForm(tags, match.params.sortOrTag)) {
      return {
        sort: null,
        tag: match.params.sortOrTag,
      };
    }
    return {
      sort: match.params.sortOrTag,
      tag: null,
    };
  }
  return {sort: null, tag: null};
}

function tagExistsByLinkForm(tags: Tag[], linkForm: string): boolean {
  return tags.some(t => t.linkForm === linkForm);
}

export function useSortOrTagToPath(s: string): string {
  const tags = useGalleryTags();
  return sortOrTagToPath(tags, s);
}

export function getGalleryTagQS(tags: Tag[], activeTag: string): string {
  const qsValue = sortOrTagToPath(tags, activeTag);
  if (!qsValue) {
    return '';
  }
  return `?galleryTag=${qsValue}`;
}

export function usePostTag(): Tag | null {
  const tags = useGalleryTags();
  return tags.find(t => t.id === POST_CATEGORY_ID) ?? null;
}

export function useDiscussionTag(): Tag | null {
  const tags = useGalleryTags();
  return tags.find(t => t.id === DISCUSSION_CATEGORY_ID) ?? null;
}
export interface InitGalleryTagsResult {
  loading: boolean;
  tags: Tag[];
}

export function addGalleryTagData(tags: Tag[]) {
  const galleryPathParams = getReportGalleryPathParams(tags);
  const pageViewProps: {[key: string]: string} = {};

  let tag: string | null = null;
  if (isOnReportGallery() && galleryPathParams != null) {
    tag = galleryPathParams.tag;
    if (tag) {
      pageViewProps.value = 'Tag Home';
    }
  } else if (isOnReportView()) {
    tag = tagFromQS();
  }

  if (tag) {
    const formattedTag = formatGalleryTag(tags, tag);
    pageViewProps.category = 'Tag';
    pageViewProps.label = formattedTag;
  }
  return pageViewProps;
}

export function useInitGalleryTags(): InitGalleryTagsResult {
  const dispatch = useDispatch();
  const {loading, gallerySpec} = useGallerySpec({
    skip: config.ENVIRONMENT_IS_PRIVATE,
  });
  const tags: Tag[] = useMemo(
    () => [...gallerySpec.categories, ...gallerySpec.tagsV2],
    [gallerySpec]
  );

  useEffect(() => {
    if (!loading) {
      dispatch(setGalleryTags(tags));
    }
  }, [dispatch, loading, tags]);

  return {loading, tags};
}

export function useGalleryTags(): Tag[] {
  const emptyArray = useMemo(() => [], []);
  return useSelector(state => state.global.galleryTags ?? emptyArray);
}

export type TrackProps = {
  value: string | null;
  activeTag: string;
  sortOrder: SortOrder;
  language: Language;
  searchQuery: string;
  mobileNavMenuOpen: boolean;
  viewer: Viewer | null;
};

export function trackOnGalleryPage(
  event: string,
  properties: TrackProps
): void {
  const namespacedEvent = getNamespacedEvent(event);
  const mergedProperties = {
    ...getMergedProperties(),
    ...properties,
  };
  wbTrack(namespacedEvent, mergedProperties);
}

export function useTrackReportActivity(reportID?: string | null): typeof track {
  const {gallerySpec} = useGallerySpecCached();
  return useMemo(() => {
    const isGalleryReport = gallerySpec.reportIDsWithTagV2IDs.some(
      r => r.id === reportID
    );
    return isGalleryReport ? trackFC : track;
  }, [reportID, gallerySpec]);
}

export function trackFC(event: string, value: string | null): void {
  const namespacedEvent = getNamespacedEvent(event);
  track(namespacedEvent, value);
}

export function track(event: string, value: string | null): void {
  const mergedProperties = {
    ...getMergedProperties(),
    value,
  };
  wbTrack(event, mergedProperties);
}

const WB_ANALYTICS_ANONYMOUS_ID_KEY = 'WB_ANALYTICS_ANONYMOUS_ID';

function wbTrack(event: string, properties: Struct = {}): void {
  window.analytics?.track(event, properties);

  let wbAnonymousId = safeLocalStorage.getItem(WB_ANALYTICS_ANONYMOUS_ID_KEY);
  if (wbAnonymousId == null) {
    wbAnonymousId = ID();
    safeLocalStorage.setItem(WB_ANALYTICS_ANONYMOUS_ID_KEY, wbAnonymousId);
  }
  const viewer = (store.getState() as RootState).viewer.viewer;

  const body = {
    method: 'track',
    args: [event, properties],
    wbAnonymousId,
    email: viewer?.email,
  };

  // eslint-disable-next-line wandb/no-unprefixed-urls
  fetch('/__WB_ANALYTICS__', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  });
}

type MergedProperties = {
  windowSize: Omit<WindowSize, 'responsiveSize'>;
  isMobile: boolean;
};

const GALLERY_EVENT_NAMESPACE = 'FullyConnected';

function getNamespacedEvent(event: string): string {
  return `${GALLERY_EVENT_NAMESPACE}/${event}`;
}

function getMergedProperties(): MergedProperties {
  return {
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    isMobile: window.innerWidth <= MOBILE_WIDTH,
  };
}

export async function fetchProjectUsages(): Promise<typeof projectUsagesData> {
  if (envIsDev) {
    await wait(2000);
    return projectUsagesData;
  }
  // eslint-disable-next-line wandb/no-unprefixed-urls
  const resp = await fetch(FC_PROJECT_USAGE_PATH, {
    headers: {'Content-Type': 'application/json'},
  });
  const data = await resp.json();
  return data;
}

export function useProjectUsages(): {
  loading: boolean;
  projectUsages: ProjectUsage[];
} {
  const [projectUsages, setProjectUsages] = useState<ProjectUsage[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const data = await fetchProjectUsages();
      setProjectUsages(data);
      setLoading(false);
    })();
  }, []);

  return {loading, projectUsages};
}

type BizzaboAPIResponse = {
  content: BizzaboAPIEvent[];
};

type BizzaboAPIEvent = {
  id: number;
  name: string;
  websiteUrl: string;
  startDate: string;
  timezone: string;
  status: string;
  type?: string[];
  category?: string[];
};

export async function fetchBizzaboEvents(): Promise<BizzaboAPIResponse> {
  if (envIsDev) {
    await wait(2000);
    return bizzaboEventData;
  }
  // eslint-disable-next-line wandb/no-unprefixed-urls
  const resp = await fetch(BIZZABO_PATH, {
    headers: {'Content-Type': 'application/json'},
  });
  const data = await resp.json();
  return data;
}

export function useBizzaboEvents(eventMetadatas: FCEventMetadata[] | null): {
  loading: boolean;
  events: BizzaboEvent[];
} {
  const [events, setEvents] = useState<BizzaboEvent[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (eventMetadatas == null) {
      return;
    }

    const eventMetadataByBizzaboID: Map<number, FCEventMetadata> = new Map(
      eventMetadatas.map(e => [e.bizzaboID, e])
    );

    (async () => {
      const data = await fetchBizzaboEvents();
      const eventsFromData: BizzaboEvent[] = _.sortBy(data.content, e => {
        const d = new Date(e.startDate);
        return -d.valueOf();
      })
        .filter(e => e.status === `published`)
        .filter(
          e =>
            e.category == null ||
            !e.category.some(c => c.trim().toLowerCase() === `exclude-on-fc`)
        )
        .map(e => ({
          id: e.id,
          name: e.name,
          url: e.websiteUrl,
          startDate: e.startDate,
          timezone: e.timezone,
          type: e.type,
          fcMetadata: eventMetadataByBizzaboID.get(e.id) ?? null,
        }));
      setEvents(eventsFromData);
      setLoading(false);
    })();
  }, [eventMetadatas]);

  return {loading, events};
}

type TagReportData = {
  reportIDSetByTagName: Map<string, Set<string>>;
  reportBelongsToTag: ReportBelongsToTagFn;
};

type ReportBelongsToTagFn = (reportID: string, tagName: string) => boolean;

export function useTagReportData(): TagReportData {
  const {gallerySpec} = useGallerySpec();
  return useMemo(() => getTagReportData(gallerySpec), [gallerySpec]);
}

function getTagReportData({
  tagsV2,
  reportIDsWithTagV2IDs,
}: GallerySpec): TagReportData {
  const tagByID = new Map(tagsV2.map(t => [t.id, t]));
  const reportIDSetByTagName: Map<string, Set<string>> = new Map();

  reportIDsWithTagV2IDs.forEach(r => {
    const onlyShowOnTagPages =
      r.onlyShowOnTagIDs != null && r.onlyShowOnTagIDs.length > 0;
    const tagIDSet = onlyShowOnTagPages
      ? new Set(r.onlyShowOnTagIDs)
      : getAncestorTagIDSetForReport(tagByID, r);

    // We should not have to do `tagIDSet.values()` here.
    // `for (const tagID of tagIDSet)` should be sufficient and functionally identical.
    // However, we have to use `.values()` because of a stupid babel bug.
    // See: https://github.com/babel/babel/issues/9530
    for (const tagID of tagIDSet.values()) {
      const tag = tagByID.get(tagID);
      if (tag == null) {
        continue;
      }

      if (!reportIDSetByTagName.has(tag.name)) {
        reportIDSetByTagName.set(tag.name, new Set());
      }
      reportIDSetByTagName.get(tag.name)?.add(r.id);
    }
  });

  const reportBelongsToTag = (reportID: string, tagName: string): boolean =>
    reportIDSetByTagName.get(tagName)?.has(reportID) ?? false;

  return {reportIDSetByTagName, reportBelongsToTag};
}

export function getAncestorTagIDSetForReport(
  tagByID: Map<string, TagV2>,
  r: ReportIDWithTagIDs
): Set<string> {
  const ancestorTagIDSet: Set<string> = new Set();
  for (const tagID of r.tagIDs) {
    let currentTagID: string | null = tagID;
    while (currentTagID != null) {
      const tag = tagByID.get(currentTagID);
      if (tag == null) {
        break;
      }
      ancestorTagIDSet.add(tag.id);
      currentTagID = tag.parent;
    }
  }
  return ancestorTagIDSet;
}

export async function triggerPrerenderRecacheForURLs(
  urls: string[]
): Promise<void> {
  if (config.ENVIRONMENT_IS_PRIVATE || !envIsProd) {
    return;
  }

  try {
    const recacheResponse = await makePrerenderRequest(
      `https://api.prerender.io/recache`,
      {
        urls,
      }
    );
    if (!recacheResponse.ok) {
      throw new Error(`bad status code ${recacheResponse.status}`);
    }
  } catch (err) {
    console.error(`Error triggering prerender recache: ${err}`);
  }
}

export async function triggerPrerenderRecacheForTags(
  tags: TagV2[]
): Promise<void> {
  const urlsToRecache = await getRecacheURLsForTags(tags);
  await triggerPrerenderRecacheForURLs(urlsToRecache);
}

export async function getRecacheURLsForTags(tags: TagV2[]): Promise<string[]> {
  const urlsToRecachePromises = tags
    .map(getFCURLForTag)
    .map(getAllQSVariations);
  const urlsToRecache = (await Promise.all(urlsToRecachePromises)).flat();

  return urlsToRecache;
}

function getFCURLForTag(t: TagV2): string {
  return `https://wandb.ai${getFCPathForTag(t)}`;
}

export function getFCPathForTag(t: TagV2): string {
  if (t.linkForm === '') {
    return `/${GALLERY_PATH_SEGMENT}`;
  }
  if (isOneOf(t.linkForm, TAG_PAGES)) {
    return `/${GALLERY_PATH_SEGMENT}/${t.linkForm}`;
  }
  return `/${GALLERY_PATH_SEGMENT}/blog/${t.linkForm}`;
}

type PrerenderPage = {
  url: string;
};

async function getAllQSVariations(url: string): Promise<string[]> {
  const variations = [url, `${url}/`];
  if (config.ENVIRONMENT_IS_PRIVATE || !envIsProd) {
    return variations;
  }

  try {
    // TODO(axel): handle pagination -- the endpoint returns 200 results which shouldn't require pagination for now
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // TODO(axel): better handle trailing slash case
      const response = await makePrerenderRequest(
        `https://api.prerender.io/search`,
        {
          query: `${url}?`,
          start: 0,
        }
      );
      const prerenderPages: PrerenderPage[] = await response.json();
      variations.push(...prerenderPages.map(r => r.url));

      const response2 = await makePrerenderRequest(
        `https://api.prerender.io/search`,
        {
          query: `${url}/?`,
          start: 0,
        }
      );

      const prerenderPages2: PrerenderPage[] = await response2.json();
      variations.push(...prerenderPages2.map(r => r.url));

      break;
    }
  } catch (err) {
    console.error(`Error getting QS variations for URL ${url}: ${err}`);
  }

  return variations;
}

function makePrerenderRequest(url: string, body: Struct): Promise<Response> {
  // eslint-disable-next-line wandb/no-unprefixed-urls
  return fetch(url, {
    method: `POST`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      // TODO(axel): handle token better
      prerenderToken: `X5HQBVZiES0CU6XSXzDM`,
      ...body,
    }),
  });
}
