import {RootState} from '../../../types/redux';
import {PanelBankSectionConfigNormalized} from '../panelBankSectionConfig/types';
import * as Types from './types';

export const makePanelPartsSelector = (ref: Types.Ref) => {
  return (state: RootState) => {
    const panelBankConfig = state.views.parts[ref.type][ref.id];
    return panelBankConfig.sectionRefs.flatMap(sectionRef => {
      const section = state.views.parts[sectionRef.type][sectionRef.id];
      return section.panelRefs.map(
        panelRef => state.views.parts[panelRef.type][panelRef.id]
      );
    });
  };
};

export const makeAllSectionsAndPanelsSelector = (
  panelBankConfigRef: Types.Ref
) => {
  return (state: RootState) => {
    const sectionRefs =
      state.views.parts['panel-bank-config'][panelBankConfigRef.id].sectionRefs;

    const sections: PanelBankSectionConfigNormalized[] = [];

    // TODO - comment back in when perf regressions are fixed
    // let panels: LayedOutPanel[] = [];

    sectionRefs.forEach(sectionRef => {
      const section =
        state.views.parts['panel-bank-section-config'][sectionRef.id];
      sections.push(section);

      // const panelRefs = section.panelRefs;
      // const newPanels = panelRefs.map(
      //   panelRef => state.views.parts.panel[panelRef.id]
      // );
      // panels = panels.concat(newPanels);
    });

    return {
      sections,
      panels: [],
    };
  };
};

export const makeNumPanelsSelector = (ref: Types.Ref, skip: boolean) => {
  return skip
    ? () => undefined
    : (state: RootState) => {
        const panelBank = state.views.parts[ref.type][ref.id];
        return panelBank.sectionRefs.reduce((numPanels, sectionRef) => {
          const section = state.views.parts[sectionRef.type][sectionRef.id];
          return numPanels + section.panelRefs.length;
        }, 0);
      };
};
