import {useMemo} from 'react';

import * as PanelBankConfigTypes from '../../state/views/panelBankConfig/types';
import {useViewAction} from './../../state/views/hooks';
import * as actions from './../../state/views/panelBankConfig/actions';

export const usePanelBankActions = (
  panelBankConfigRef: PanelBankConfigTypes.Ref
) => {
  const openOrCloseAllSections = useViewAction(
    panelBankConfigRef,
    actions.openOrCloseAllSections
  );

  const pinSection = useViewAction(panelBankConfigRef, actions.pinSection);

  const setCurrentSearch = useViewAction(
    panelBankConfigRef,
    actions.setCurrentPanelSearch
  );

  const updateSearchHistory = useViewAction(
    panelBankConfigRef,
    actions.updatePanelSearchHistory
  );

  const updateSettingsAndSortPanels = useViewAction(
    panelBankConfigRef,
    actions.updateSettingsAndSortPanels
  );

  const updatePanelBankSettings = useViewAction(
    panelBankConfigRef,
    actions.updateSettings
  );
  return useMemo(
    () => ({
      openOrCloseAllSections,
      pinSection,
      setCurrentSearch,
      updatePanelBankSettings,
      updateSearchHistory,
      updateSettingsAndSortPanels,
    }),
    [
      openOrCloseAllSections,
      pinSection,
      setCurrentSearch,
      updatePanelBankSettings,
      updateSearchHistory,
      updateSettingsAndSortPanels,
    ]
  );
};
