import {isEqual} from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import {useViewAction} from '../../../state/views/hooks';
import * as PanelBankConfigActions from '../../../state/views/panelBankConfig/actions';
import {Ref as PanelBankConfigRef} from '../../../state/views/panelBankConfig/types';
import {PanelBankSectionConfigNormalized} from '../../../state/views/panelBankSectionConfig/types';
import * as WorkspaceSettingsActions from '../../../state/views/workspaceSettings/actions';
import {makeWorkspaceSettingOverridesSelector} from '../../../state/views/workspaceSettings/selectors';
import {Ref as WorkspaceSettingsRef} from '../../../state/views/workspaceSettings/types';
import {DispatchableAction} from '../../../types/redux';
import {useRampFlagPanelGenSetting} from '../../../util/rampFeatureFlags';
import {useDetectChanges} from '../../../util/react-help/detectReactPropChanges';
import {SmoothingTypeValues} from '../../elements/SmoothingConfig';
import {useKeyInfoQueryContext} from '../../MultiRunWorkspace/KeyInfoQueryContext';
import {usePanelBankConfigRefContext} from '../../PanelBank/ConfigRefContext';
import {getLinePlotSettings} from './getLinePlotSettings';
import {createWorkspaceXAxisOptions} from './LinePlotXAxis';
import {LinePlotSettings, SettingSelectOptionType} from './types';
import {useUpdateShouldAutoGeneratePanels} from './useUpdateShouldAutoGeneratePanels';

// TODO (joyce) - change to Required<OrganizedSettings> when all settings
// are implemented in new UX
export type WorkspaceSettingsContextType = {
  // refs
  workspaceSettingsRef: WorkspaceSettingsRef;
  panelBankConfigRef: PanelBankConfigRef;

  // settings
  linePlot: {
    ignoreOutliers: boolean | undefined;
    xAxis: string | undefined;
    xAxisMin: number | undefined;
    xAxisMax: number | undefined;
    smoothingWeight: number | undefined;
    smoothingType: SmoothingTypeValues | undefined;
    suppressLegends: boolean | undefined;
  };
  shouldAutoGeneratePanels: boolean;
  sectionOverrides: Record<string, PanelBankSectionConfigNormalized[]>;
  getWorkspaceXAxisOptions: () => SettingSelectOptionType[];

  // updaters
  updateLinePlotWorkspaceSettings: (
    settings: Partial<LinePlotSettings>
  ) => DispatchableAction;
  updateAllLinePlotSectionSettings: (
    settings: Partial<LinePlotSettings>
  ) => DispatchableAction;
  updateShouldAutoGeneratePanels: (shouldEnable: boolean) => void;
};

export const WorkspaceSettingsContext = createContext<
  WorkspaceSettingsContextType | undefined
>(undefined);

type WorkspaceSettingsProps = {
  workspaceSettingsRef: WorkspaceSettingsRef;
  children: React.ReactNode;
};

/**
 * This context provider is used in conjuction with SectionSettingsContextProvider.
 * We use these two context providers to handle cascading settings logic.
 */
export const WorkspaceSettingsContextProvider = ({
  workspaceSettingsRef,
  children,
}: WorkspaceSettingsProps) => {
  const {panelBankConfigRef} = usePanelBankConfigRefContext();
  const {workspaceSettings, sectionOverrides} = useSelector(
    makeWorkspaceSettingOverridesSelector(
      workspaceSettingsRef,
      panelBankConfigRef
    ),
    shallowEqual
  );

  /**
   * We use history key info to populate the x-axis options. This can get expensive
   * for workspaces with lots of metrics. We should only calculate when needed and update
   * when keys change.
   */
  const {
    keyInfoQuery: {historyKeyInfo},
  } = useKeyInfoQueryContext();
  const [xAxisOptions, setXAxisOptions] = useState<
    SettingSelectOptionType[] | undefined
  >();
  const getWorkspaceXAxisOptions = useCallback(() => {
    if (xAxisOptions == null) {
      const newOptions = createWorkspaceXAxisOptions(historyKeyInfo);
      setXAxisOptions(newOptions);
      return newOptions;
    }
    return xAxisOptions;
  }, [historyKeyInfo, xAxisOptions]);

  useEffect(() => {
    // Make sure to x axis options are re-calculated when historyKeyInfo keys change
    if (xAxisOptions != null) {
      const newOptions = createWorkspaceXAxisOptions(historyKeyInfo);
      if (!isEqual(newOptions, xAxisOptions)) {
        setXAxisOptions(newOptions);
      }
    }
  }, [historyKeyInfo, xAxisOptions]);

  const {
    ignoreOutliers,
    xAxis,
    xAxisMin,
    xAxisMax,
    smoothingWeight,
    smoothingType,
    suppressLegends,
  } = getLinePlotSettings(workspaceSettings);

  const updateLinePlotWorkspaceSettings = useViewAction(
    workspaceSettingsRef,
    WorkspaceSettingsActions.updateLinePlotWorkspaceSettings
  );

  const updateAllLinePlotSectionSettings = useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.updateAllLinePlotSectionSettings
  );

  const isPanelGenSettingRampEnabled = useRampFlagPanelGenSetting();
  const shouldAutoGeneratePanels = isPanelGenSettingRampEnabled
    ? workspaceSettings?.shouldAutoGeneratePanels ?? true
    : true;
  const updateShouldAutoGeneratePanels =
    useUpdateShouldAutoGeneratePanels(workspaceSettingsRef);

  const value = useMemo(
    () => ({
      // refs
      workspaceSettingsRef,
      panelBankConfigRef,

      // settings
      linePlot: {
        ignoreOutliers,
        xAxis,
        xAxisMin,
        xAxisMax,
        smoothingWeight,
        smoothingType,
        suppressLegends,
      },
      shouldAutoGeneratePanels,
      sectionOverrides,
      getWorkspaceXAxisOptions,

      // updaters
      updateLinePlotWorkspaceSettings,
      updateAllLinePlotSectionSettings,
      updateShouldAutoGeneratePanels,
    }),
    [
      workspaceSettingsRef,
      panelBankConfigRef,
      ignoreOutliers,
      xAxis,
      xAxisMin,
      xAxisMax,
      smoothingWeight,
      smoothingType,
      suppressLegends,
      shouldAutoGeneratePanels,
      sectionOverrides,
      getWorkspaceXAxisOptions,
      updateLinePlotWorkspaceSettings,
      updateAllLinePlotSectionSettings,
      updateShouldAutoGeneratePanels,
    ]
  );

  useDetectChanges('WorkspaceSettingsContextProvider', {
    workspaceSettingsRef,
    panelBankConfigRef,
    ignoreOutliers,
    xAxis,
    xAxisMin,
    xAxisMax,
    smoothingWeight,
    smoothingType,
    shouldAutoGeneratePanels,
    sectionOverrides,
    getWorkspaceXAxisOptions,
    updateLinePlotWorkspaceSettings,
    updateAllLinePlotSectionSettings,
    updateShouldAutoGeneratePanels,
  });

  return (
    <WorkspaceSettingsContext.Provider value={value}>
      {children}
    </WorkspaceSettingsContext.Provider>
  );
};

export const useWorkspaceSettingsContext = (): WorkspaceSettingsContextType => {
  const value = useContext(WorkspaceSettingsContext);

  if (value == null) {
    throw new Error(
      'useWorkspaceSettingsContext must be used within a WorkspaceSettingsContextProvider'
    );
  }

  return value;
};

export const useWorkspaceSettingsContextMaybe = () => {
  return useContext(WorkspaceSettingsContext);
};
