import {createAction} from 'typesafe-actions';

import {GeneralSettings} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {StateType as ExpectedPanelsState} from '../../expectedPanels/reducer';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import {Ref as WorkspaceSettingsRef} from './types';

export const disableAutoGeneratePanels = createAction(
  '@view/workspaceSettings/disableAutoGeneratePanels',
  action =>
    (
      ref: WorkspaceSettingsRef,
      panelBankConfigRef: PanelBankConfigTypes.Ref,
      expectedPanels: ExpectedPanelsState,
      undoPanelAutoGenInfo?: PanelBankConfigTypes.UndoPanelAutoGenInfo
    ) =>
      action({ref, panelBankConfigRef, expectedPanels, undoPanelAutoGenInfo})
);

export const enableAutoGeneratePanels = createAction(
  '@view/workspaceSettings/enableAutoGeneratePanels',
  action =>
    (
      ref: WorkspaceSettingsRef,
      panelBankConfigRef: PanelBankConfigTypes.Ref,
      expectedPanels: ExpectedPanelsState
    ) =>
      action({ref, panelBankConfigRef, expectedPanels})
);

export const updateGeneralWorkspaceSettings = createAction(
  '@view/workspaceSettings/general/update',
  action => (ref: WorkspaceSettingsRef, settings: Partial<GeneralSettings>) =>
    action({ref, settings})
);

export const updateLinePlotWorkspaceSettings = createAction(
  '@view/workspaceSettings/linePlot/update',
  action => (ref: WorkspaceSettingsRef, settings: Partial<LinePlotSettings>) =>
    action({ref, settings})
);
