import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {Link, LinkNoCrawl} from '@wandb/weave/common/util/links';
import {addUTCTimezoneIfNotPresent} from '@wandb/weave/common/util/time';
import {Icon} from '@wandb/weave/components/Icon';
import classNames from 'classnames';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {SortFunction} from 'react-table';
import TimeAgo from 'react-timeago';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Popup} from 'semantic-ui-react';

import {
  Maybe,
  useStarViewMutation,
  useUnstarViewMutation,
  useUpsertView2Mutation,
} from '../generated/graphql';
import {Graph} from '../types/graphql';
import {
  DISCUSSION_CATEGORY_ID,
  ReportMetadata,
  useReportMetadata,
} from '../util/gallery';
import {isPublicRead} from '../util/projectAccess';
import * as Urls from '../util/urls';
import ImageIconHeader from './ImageIconHeader';
import WBReactTable from './WBReactTable';

export interface ReportsTableReport {
  id: string;
  displayName: string;
  description: string;
  user: {
    id: string;
    username: string;
    photoUrl: string;
    admin: boolean;
  };
  project: {
    id: string;
    entityName: string;
    name: string;
    access: Maybe<string>;
  };
  updatedAt: string;
  starCount: number;
  starred: boolean;
  showcasedAt: Maybe<string>;
  previewUrl: Maybe<string>;
  viewCount?: number;
}

// TODO: move into src/state/graphql
export const reportsTableFragment = gql`
  fragment ReportsTableFragment on View {
    id
    displayName
    description
    user {
      id
      username
      photoUrl
      admin
    }
    project {
      id
      name
      entityName
      access
    }
    starCount
    createdAt
    updatedAt
    starred
    showcasedAt
    previewUrl
    viewCount
  }
`;

interface ReportsTableProps {
  data: Graph<ReportsTableReport> | null;
  pageSize?: number | null;
  onChangePageSize?: (pageSize: number) => void;
  showShowcase?: boolean;
  suppressHeader?: boolean;
}
const ReportsTable: React.FC<ReportsTableProps> = props => {
  const {data, pageSize, onChangePageSize, showShowcase} = props;
  const [starView] = useStarViewMutation();
  const [unstarView] = useUnstarViewMutation();
  const [upsertView] = useUpsertView2Mutation();

  const starReport = useCallback(
    (id: string) => starView({variables: {id}}),
    [starView]
  );
  const unstarReport = useCallback(
    (id: string) => unstarView({variables: {id}}),
    [unstarView]
  );
  const showcaseReport = useCallback(
    (r: ReportsTableReport, analyticsLocation: string) => {
      window.analytics?.track('Showcase Report Clicked', {
        location: analyticsLocation,
        reportName: r.displayName,
        reportID: r.id,
        projectName: r.project.name,
      });
      upsertView({
        variables: {id: r.id, showcasedAt: new Date().toISOString()},
      });
    },
    [upsertView]
  );

  const toggleStarred = useCallback(
    (r: ReportsTableReport) =>
      r.starred ? unstarReport(r.id) : starReport(r.id),
    [unstarReport, starReport]
  );

  // The React Table cells are of type react.element so sorting is broken.
  // Extract the relevant values based on the element content. This is pretty
  // brittle so I need to figure out a more consistent way of doing this.
  // Edit this sort function if you add/remove columns from the Reports table.
  const defaultSortMethod: SortFunction = useCallback((a, b, desc) => {
    // check compare type, and set values accordingly
    let aVal = a;
    let bVal = b;
    // is date value
    if (a.props.date) {
      aVal = a.props.date;
      bVal = b.props.date;
    }
    // is showcase state
    else if (a.props.content) {
      aVal = a.props.content;
      bVal = b.props.content;
    }
    // is star count (children will be an array since it contains the heart
    // symbol and the heart count)
    else if (
      a.props.children &&
      Array.isArray(a.props.children) &&
      a.props.children.length === 2 &&
      a.props.children[0].props.name === 'heart'
    ) {
      aVal = a.props.children[1];
      bVal = b.props.children[1];
    }
    // is link
    else if (a.props.to) {
      aVal = (a.props.children as string).toLowerCase();
      bVal = (b.props.children as string).toLowerCase();
    }

    // perform sort
    if (aVal > bVal) {
      return -1;
    }
    if (bVal > aVal) {
      return 1;
    }
    return 0;
  }, []);

  // If you edit this, edit defaultSortFunction() above to match.
  const columns = [
    {Header: 'Title', accessor: 'title', width: 300},
    ...(showShowcase
      ? [
          {
            Header: 'Showcase',
            accessor: 'showcase',
            width: 100,
            className: 'showcase-cell',
            headerClassName: 'showcase-cell',
          },
        ]
      : []),
    {Header: '', accessor: 'stars', width: 60, className: 'stars-cell'},
    {Header: 'Project', accessor: 'project', width: 200},
    {Header: 'Author', accessor: 'author'},
    {Header: 'Last Edit', accessor: 'lastEdited'},
  ];

  // Reports without projects are either discussions or blog posts in FC,
  // grab their FC report metadata to form the correct link
  const projectlessReportIDs = useMemo(
    () =>
      (data?.edges ?? [])
        .filter(({node: r}) => r.project == null)
        .map(({node: r}) => r.id),
    [data]
  );
  const {loading: reportsLoading, reportMetadatas} =
    useReportMetadata(projectlessReportIDs);
  const projectlessReportByID = useMemo(() => {
    const reportMap = new Map<string, ReportMetadata>();
    if (!reportsLoading) {
      (reportMetadatas ?? []).forEach(r => {
        reportMap.set(r.id, r);
      });
    }
    return reportMap;
  }, [reportsLoading, reportMetadatas]);

  const reports = useMemo(
    () =>
      _.compact(
        data?.edges.map(({node: r}) => {
          // if we can't find a projectless report's FC metadata, omit it.
          const projectlessReportMetadata = projectlessReportByID.get(r.id);
          if (r.project == null && projectlessReportMetadata == null) {
            return null;
          }
          const showcaseDisabled =
            r.project != null ? !isPublicRead(r.project) : true;
          const link =
            r.project != null
              ? Urls.reportView({
                  entityName: r.project.entityName,
                  projectName: r.project.name,
                  reportID: r.id,
                  reportName: r.displayName,
                })
              : (projectlessReportMetadata?.tags?.some(
                  t => t.id === DISCUSSION_CATEGORY_ID
                )
                  ? Urls.galleryDiscussionView
                  : Urls.galleryPostEdit)({
                  entityName: projectlessReportMetadata?.entityName ?? '',
                  reportID: r.id,
                  reportName: r.displayName,
                });
          return {
            searchString: r.displayName,
            row: {
              title: <Link to={link}>{r.displayName}</Link>,
              ...(showShowcase
                ? {
                    showcase:
                      r.showcasedAt == null ? (
                        <Popup
                          on="hover"
                          position="top center"
                          popperModifiers={{
                            preventOverflow: {enabled: false},
                          }}
                          content={
                            !showcaseDisabled
                              ? 'Showcase this report'
                              : 'Make this project public to showcase this report'
                          }
                          trigger={
                            <span>
                              <LegacyWBIcon
                                name="plus"
                                data-test="showcase-report-button"
                                className={classNames({
                                  disabled: showcaseDisabled,
                                })}
                                onClick={() => {
                                  if (showcaseDisabled) {
                                    return;
                                  }

                                  showcaseReport(r, 'reports table');
                                }}
                              />
                            </span>
                          }
                        />
                      ) : (
                        <Popup
                          on="hover"
                          position="top center"
                          popperModifiers={{
                            preventOverflow: {enabled: false},
                          }}
                          content="Featured at the top of your profile"
                          trigger={
                            <span>
                              <LegacyWBIcon
                                name="gallery"
                                data-test="go-to-showcase-button"
                                onClick={scrollToReportShowcase}
                              />
                            </span>
                          }
                        />
                      ),
                  }
                : {}),
              stars: (
                <div
                  style={{alignItems: 'center', display: 'flex', gap: '4px'}}>
                  <Icon
                    onClick={() => toggleStarred(r)}
                    name={r.starred ? 'star-filled' : 'star'}
                    style={{cursor: 'pointer'}}
                  />
                  {r.starCount}
                </div>
              ),
              project:
                r.project != null ? (
                  <LinkNoCrawl
                    to={`/${r.project.entityName}/${r.project.name}`}>
                    {r.project.name}
                  </LinkNoCrawl>
                ) : (
                  <></>
                ),
              author: (
                <LinkNoCrawl to={`/${r.user.username}`}>
                  {r.user.username}
                </LinkNoCrawl>
              ),
              lastEdited: (
                <TimeAgo date={addUTCTimezoneIfNotPresent(r.updatedAt)} />
              ),
            },
          };
        })
      ),
    [data, projectlessReportByID, showShowcase, showcaseReport, toggleStarred]
  );

  const renderHiddenRow = useCallback(
    (r: (typeof reports)[number]['row'], i: number) => (
      <div key={i} style={{display: 'none'}}>
        {r.title}
      </div>
    ),
    // eslint-disable-next-line
    []
  );

  if (reports.length === 0) {
    return <div />;
  }

  return (
    <>
      {!props.suppressHeader && (
        <ImageIconHeader icon="report" text="Reports" />
      )}
      <WBReactTable
        className="reports-table"
        pageSize={pageSize ?? undefined}
        onChangePageSize={onChangePageSize}
        data={reports}
        columns={columns}
        renderHiddenRow={renderHiddenRow}
        defaultSortMethod={defaultSortMethod}
      />
    </>
  );
};

export default ReportsTable;

function scrollToReportShowcase() {
  const el = document.querySelector('#report-showcase');
  if (el != null) {
    el.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'smooth',
    });
  }
}
