import _ from 'lodash';

// this file includes utility functions
// for storage & artifact tracking (aka reference)

export const ARTIFACT_TRACKING_LIMIT_GB = undefined;

export const bytesInGB = 1000000000;
export const bytesInMB = 1000000;
export const bytesInTB = bytesInGB * 1000;

export const freeStorageInGB = 100;
export const freeWeaveIngestionInMB = 100;

export const additionalStoragePricePerGB = 0.03;
export const storagePrices = [0.0, additionalStoragePricePerGB];
export const artifactPrices = [0.0, 0.0];

export const storageFeatures = [
  `Free up to ${freeStorageInGB} GB`,
  `$0.03 per GB over ${freeStorageInGB} GB`,
];

export const artifactFeatures = [`Free`];

export function getTotalStorageBytes(
  entities?: Array<{storageBytes: number}>
): number | undefined {
  if (entities == null) {
    return undefined;
  }

  return _.sumBy(entities, 'storageBytes');
}

export function getTotalArtifactTrackingBytes(
  entities?: Array<{referenceBytes: number}>
): number | undefined {
  if (entities == null) {
    return undefined;
  }

  return _.sumBy(entities, 'referenceBytes');
}

export const TRACKED_HOURS = [
  `Tier 1 up to 5,000 tracked hours`,
  `Tier 2 up to 10,000 tracked hours`,
  `Tier 3 up to 15,000 tracked hours`,
  `Over 15,000 hours, contact us`,
];

const BYTE_TIER_1 = freeStorageInGB * bytesInGB;

export const STORAGE_PRICE_PER_GB_TIER_1 = 0.03;

// TODO: move this calculation to backend
export function getEstimatedStorageCharge(
  storageByte: number,
  limit: number = BYTE_TIER_1
): number {
  let totalEstimate = 0;
  if (storageByte > limit) {
    totalEstimate +=
      STORAGE_PRICE_PER_GB_TIER_1 * ((storageByte - limit) / bytesInGB);
  }

  return totalEstimate;
}

// TODO: move this calculation to backend
export function getEstimatedArtifactReferenceCharge(
  referenceByte: number
): number {
  return 0;
}
