import {debounce} from 'lodash';
import React from 'react';

import {maybePluralize} from '../../../util/uihelpers';

type ClearSettingsRowProps = {
  count: number;
  type: string;
  overrideType?: string;
  clearSetting?: () => void;
};

export const ClearSettingsRow = ({
  count,
  type,
  overrideType,
  clearSetting,
}: ClearSettingsRowProps) => {
  if (clearSetting == null) {
    return null;
  }

  const debouncedClearSetting =
    clearSetting == null ? undefined : debounce(clearSetting, 500);

  return (
    <div>
      {count > 0 && (
        <span>
          {maybePluralize(count, overrideType ?? 'panel')}
          {count === 1 ? ' has ' : ' have '}
          overrides.{' '}
        </span>
      )}
      <span
        className="font-semibold text-teal-600 hover:text-teal-550"
        role="button"
        onClick={debouncedClearSetting}>
        Clear
        {count === 0 && ` ${type} setting`}
        {count > 1 && ' all'}
      </span>
    </div>
  );
};
