import {useMemo} from 'react';
import {create} from 'zustand';

import {TooltipNumberOfRunsOptions} from '../../components/WorkspaceDrawer/Settings/types';
import {
  POINT_VISUALIZATION_OPTIONS,
  PointVisualizationOptions,
} from '../../components/WorkspaceSettingsModal/controls/PointVisualizationTypes';
import {OrganizationPrefix} from './../../components/PanelBank/types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {usePointVisualizationMethod} from './../../components/PanelRunsLinePlot/usePointVisualizationMethod';
import {useViewer} from './../../state/viewer/hooks';
import {
  DEFAULT_MAX_NUM_BUCKETED_RUNS,
  DEFAULT_MAX_NUM_RUNS,
  DEFAULT_NUM_RUNS_NEW,
} from './defaults';

// defaults are set from https://wandb.atlassian.net/browse/WB-17958
export const DefaultWorkspaceSettings = {
  pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.SamplingByDefault,

  // sections
  showEmptySections: false,
  autoOrganizePrefix: OrganizationPrefix.FirstPrefix,
  sortAlphabetically: false,

  // tooltips
  tooltipNumberOfRuns: TooltipNumberOfRunsOptions.Default,
  highlightedCompanionRunOnly: false,
  colorRunNames: true,

  // runs
  showMinMaxOnHover: false,
};

export type WorkspaceSettings = {
  /**
   * For a period of time users were able to set a max runs values across all workspaces through a single value set as a global custom default. This value is going to hang around in some user data and we need a way to use it as the default in workspaces when the feature flag is flipped. This lets us read that value in (if it exists) and use it in the logic below
   */
  deprecatedMaxRuns: number | null;

  // sections
  showEmptySections: boolean;
  autoOrganizePrefix: OrganizationPrefix;
  sortAlphabetically: boolean;

  // tooltips
  tooltipNumberOfRuns: TooltipNumberOfRunsOptions; // controls how many runs show in the tooltips
  highlightedCompanionRunOnly: boolean; // controls showing only one run on companion plots
  colorRunNames: boolean; // controls whether run names inherit colors
};

export type DefaultedWorkspaceSettings = {
  maxRuns?: number; // controls number of runs shown per runs line plot
  pointVisualizationMethod?: PointVisualizationOptions; // controls if a user is bucketing w/ weave or using legacy sampling
  showMinMaxOnHover: boolean; // controls whether to show outliers in runs line plots
};

export type CombinedWorkspaceSettings = WorkspaceSettings &
  DefaultedWorkspaceSettings;

/**
 * Editable Write vs Editable Read
 * The types here are messier than I'd like, but the problem is that we want to discourage reading certain values off of the zustand store. The reason is that those values can only be safely read in context with other workspace settings that zustand is unaware of. E.g. the number of maxRuns allowed is different at the time of this comment between bucketing and sampling in order to safeguard the API from known performance issues with overly expansive bucketing queries.
 *
 * That said, those values can still be _written_ because of the way that data flows between the workspace spec and zustand (writes to the spec, which come in as props and get written to zustand, and then the reads either come from zustand OR from specific hooks like useMaxRuns which access zustand in conjunction with other app context)
 */
export type EditableWorkspaceSettingsWrite = Omit<
  CombinedWorkspaceSettings,
  'deprecatedMaxRuns'
>;
export type EditableWorkspaceSettingsRead = Omit<
  EditableWorkspaceSettingsWrite,
  'maxRuns' | 'pointVisualizationMethod'
>;

export type WorkspaceSettingsUpdaters = {
  // whenever a workspace unmounts we need to clear it so it doesn't hang around for a new workspace. The new workspace will mount its own value
  reset: () => void;
  // zustand merges by default so we can use a single updater
  update: (state: Partial<CombinedWorkspaceSettings>) => void;
};

export const WORKSPACE_SETTING_SOURCE: Record<
  keyof Omit<CombinedWorkspaceSettings, 'deprecatedMaxRuns'>,
  'panelBankSettings' | 'panelSettings'
> = {
  autoOrganizePrefix: 'panelBankSettings',
  colorRunNames: 'panelSettings',
  highlightedCompanionRunOnly: 'panelBankSettings',
  maxRuns: 'panelSettings',
  pointVisualizationMethod: 'panelSettings',
  showEmptySections: 'panelBankSettings',
  sortAlphabetically: 'panelBankSettings',
  showMinMaxOnHover: 'panelSettings',
  tooltipNumberOfRuns: 'panelSettings',
};

export const getWorkspaceSettings = create<
  CombinedWorkspaceSettings & WorkspaceSettingsUpdaters
>()(set => ({
  // feature defaults
  deprecatedMaxRuns: null,
  pointVisualizationMethod: DefaultWorkspaceSettings.pointVisualizationMethod,

  // handlers
  reset: () =>
    set(() => ({
      colorRunNames: DefaultWorkspaceSettings.colorRunNames,
      highlightedCompanionRunOnly:
        DefaultWorkspaceSettings.highlightedCompanionRunOnly,
      tooltipNumberOfRuns: DefaultWorkspaceSettings.tooltipNumberOfRuns,
    })),
  update: (newState: Partial<WorkspaceSettings>) => {
    set(() => newState);
  },

  // sections
  showEmptySections: DefaultWorkspaceSettings.showEmptySections,
  autoOrganizePrefix: DefaultWorkspaceSettings.autoOrganizePrefix,
  sortAlphabetically: DefaultWorkspaceSettings.sortAlphabetically,

  // tooltips
  colorRunNames: DefaultWorkspaceSettings.colorRunNames,
  highlightedCompanionRunOnly:
    DefaultWorkspaceSettings.highlightedCompanionRunOnly,

  // runs
  showMinMaxOnHover: DefaultWorkspaceSettings.showMinMaxOnHover,
  tooltipNumberOfRuns: DefaultWorkspaceSettings.tooltipNumberOfRuns,
}));

/**
 * These are the values that can be safely read from the zustand store without needing to be modified based on other app data. If any of these values do need to be modified by app state, they should move into custom hooks and be removed from this hook and EditableWorkspaceSettingsRead
 */
export const useWorkspaceSettings =
  (): Required<EditableWorkspaceSettingsRead> => {
    const settings = getWorkspaceSettings(s => s);

    return useMemo(
      () => ({
        autoOrganizePrefix: settings.autoOrganizePrefix,
        colorRunNames: settings.colorRunNames,
        highlightedCompanionRunOnly: settings.highlightedCompanionRunOnly,
        showEmptySections: settings.showEmptySections,
        sortAlphabetically: settings.sortAlphabetically,
        showMinMaxOnHover: settings.showMinMaxOnHover,
        tooltipNumberOfRuns: settings.tooltipNumberOfRuns,
      }),
      [settings]
    );
  };

// This hook keeps a user's workspace max run setting from leaking if the feature flag is turned off
export const useMaxRuns = (
  config: {
    getPointVisualizationMethod: typeof usePointVisualizationMethod;
    getSettings: typeof getWorkspaceSettings;
    getViewer: typeof useViewer;
  } = {
    getPointVisualizationMethod: usePointVisualizationMethod,
    getSettings: getWorkspaceSettings,
    getViewer: useViewer,
  }
) => {
  const viewer = config.getViewer();
  const {deprecatedMaxRuns, maxRuns, update} = config.getSettings(state => ({
    deprecatedMaxRuns: state.deprecatedMaxRuns,
    maxRuns: state.maxRuns,
    update: state.update,
  }));
  const pointVisualizationMethod = config.getPointVisualizationMethod();

  // @ts-ignore - custom max runs is deprecated so it's not on the type
  const legacyRuns = viewer?.userInfo?.betaFeatures?.['custom-max-runs'];

  if (typeof legacyRuns === 'number' && legacyRuns !== deprecatedMaxRuns) {
    update({deprecatedMaxRuns: legacyRuns});
  }

  /**
   * 1. If a user has a max runs setting on the workspace use that
   * 2. If a user has a deprecated max runs setting from custom defaults use that
   * 3. Otherwise return the default
   */

  let allowedRuns = DEFAULT_NUM_RUNS_NEW;

  /**
   * Make sure a user can't sneak in values higher than the max
   */
  if (maxRuns) {
    allowedRuns =
      maxRuns > DEFAULT_MAX_NUM_RUNS ? DEFAULT_MAX_NUM_RUNS : maxRuns;
  } else if (deprecatedMaxRuns) {
    allowedRuns =
      deprecatedMaxRuns > DEFAULT_MAX_NUM_RUNS
        ? DEFAULT_MAX_NUM_RUNS
        : deprecatedMaxRuns;
  }

  return pointVisualizationMethod ===
    POINT_VISUALIZATION_OPTIONS.BucketingGorilla &&
    allowedRuns > DEFAULT_MAX_NUM_BUCKETED_RUNS
    ? DEFAULT_MAX_NUM_BUCKETED_RUNS
    : allowedRuns;
};
