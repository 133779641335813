import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {areXAxisSettingsActive} from '../../../components/WorkspaceDrawer/Settings/utils';
import type {RootState} from '../../../types/redux';
import {LayedOutPanel} from '../../../util/panelTypes';
import {usePart} from '../hooks';
import {makeAllSectionsAndPanelsSelector} from '../panelBankConfig/selectors';
import {Ref as PanelBankConfigRef} from '../panelBankConfig/types';
import {PanelBankSectionConfigNormalized} from '../panelBankSectionConfig/types';
import {Ref as WorkspaceSettingsRef} from './types';

export const makeWorkspaceSettingOverridesSelector = (
  workspaceSettingsRef: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigRef
) => {
  return (state: RootState) => {
    const workspaceSettings = usePart(workspaceSettingsRef);
    const allSectionsAndPanelsSelector =
      makeAllSectionsAndPanelsSelector(panelBankConfigRef);
    const {sections, panels} = allSectionsAndPanelsSelector(state);

    return {
      workspaceSettings,
      sectionOverrides: {
        xAxis: filteredOverrideSections({
          sections,
          workspaceLinePlotSettings: workspaceSettings?.linePlot,
          areSettingsActive: areXAxisSettingsActive,
          fields: ['xAxis', 'xAxisMin', 'xAxisMax'],
        }),
        //  TODO - comment back once perf issues are addressed around this

        // smoothing: filteredOverrideSections({
        //   sections,
        //   workspaceLinePlotSettings: workspaceSettings?.linePlot,
        //   areSettingsActive: areSmoothingSettingsActive,
        //   fields: ['smoothingType', 'smoothingWeight'],
        // }),
        // },
        // panelOverrides: {
        //   smoothing: filteredOverridePanels({
        //     panels,
        //     workspaceLinePlotSettings: workspaceSettings?.linePlot,
        //     areSettingsActive: areSmoothingSettingsActive,
        //     fields: ['smoothingType', 'smoothingWeight'],
        //   }),
      },
    };
  };
};

/**
 * Determines how many sections have a setting that overrides the
 * workspace setting. Note: section settings take precedence over workspace settings
 */
export const filteredOverrideSections = ({
  sections,
  workspaceLinePlotSettings,
  areSettingsActive,
  fields,
}: {
  sections: PanelBankSectionConfigNormalized[];
  workspaceLinePlotSettings: LinePlotSettings | undefined;
  areSettingsActive: (settings: LinePlotSettings | undefined) => boolean;
  fields: string[];
}) => {
  if (workspaceLinePlotSettings == null) {
    return [];
  }

  // If workspace setting hasn't been changed, then stop early
  if (!areSettingsActive(workspaceLinePlotSettings)) {
    return [];
  }

  return sections.filter(section => {
    const linePlotSectionSettings = section?.sectionSettings?.linePlot;
    if (!areSettingsActive(linePlotSectionSettings)) {
      return false;
    }

    const tests = fields
      .map(field =>
        isOverriden(field, workspaceLinePlotSettings, linePlotSectionSettings)
      )
      .some(val => val);

    return tests;
  });
};

/**
 * Determines how many line plot panels have a setting that overrides the
 * workspace setting.
 * Note 1: panels settings take precedence over workspace settings.
 * Note 2: A majority of global settings only apply to runs line plots.
 */
export const filteredOverridePanels = ({
  panels,
  workspaceLinePlotSettings,
  areSettingsActive,
  fields,
}: {
  panels: LayedOutPanel[];
  workspaceLinePlotSettings: LinePlotSettings | undefined;
  areSettingsActive: (settings: LinePlotSettings | undefined) => boolean;
  fields: string[];
}) => {
  if (workspaceLinePlotSettings == null) {
    return [];
  }

  // If workspace setting hasn't been changed, then stop early
  if (!areSettingsActive(workspaceLinePlotSettings)) {
    return [];
  }

  return panels.filter(panel => {
    if (panel.viewType !== 'Run History Line Plot') {
      return false;
    }

    if (!areSettingsActive(panel.config)) {
      return false;
    }

    const tests = fields
      .map(field => isOverriden(field, workspaceLinePlotSettings, panel.config))
      .some(val => val);

    return tests;
  });
};

const isOverriden = (
  field: string,
  parentSetting: Record<string, any> | undefined,
  childSetting: Record<string, any> | undefined
) => {
  return (
    childSetting != null &&
    field in childSetting &&
    (parentSetting == null || parentSetting[field] !== childSetting[field])
  );
};
