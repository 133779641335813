import {logToDataDog, PERF_LOG_PREFIX} from './../../util/profiler';
import {RSDQ_Query_Data} from './apiTypes';

export function unwrapRespRSDQ(resp: {
  data: {
    project: {
      runs: {
        delta: Array<{
          run: {
            sampledHistory: Array<Array<{}>>;
          };
        }>;
      };
    };
  };
}) {
  try {
    return {
      numRuns: resp.data?.project?.runs?.delta?.length ?? 0,
      numPoints:
        resp.data?.project?.runs?.delta?.reduce((acc, d) => {
          return acc + d.run?.sampledHistory?.flat().length;
        }, 0) ?? 0,
    };
  } catch (e) {
    return {
      numRuns: 0,
      numPoints: 0,
    };
  }
}

export function unwrapRespBRSDQ(resp: {
  data: {
    project: {
      runs: {
        deltas: {
          delta: Array<{
            run: {
              bucketedHistory: Array<Array<{}>>;
            };
          }>;
        };
      };
    };
  };
}) {
  try {
    return {
      numRuns: resp.data?.project?.runs?.deltas?.delta?.length ?? 0,
      numPoints:
        resp.data?.project?.runs?.deltas?.delta?.reduce((acc, d) => {
          return acc + d.run?.bucketedHistory?.flat().length;
        }, 0) ?? 0,
    };
  } catch (e) {
    return {
      numRuns: 0,
      numPoints: 0,
    };
  }
}
export function processShadow(
  promises: [rsdq: Promise<RSDQ_Query_Data>, brsdq: Promise<RSDQ_Query_Data>]
) {
  /**
   * This waits for the resolution of both promises, compares the timestamps, and then logs the results
   */
  Promise.allSettled(promises)
    .then(results => {
      try {
        // only log on successful resolution of all promises
        if (results.map(r => r.status).every(s => s === 'fulfilled')) {
          // @ts-ignore don't know why this is failing in CI
          const {value: brsdqData} = results
            .filter(r => r.status === 'fulfilled')
            // @ts-ignore don't know why this is failing in CI
            .find(r => r.value!.query === 'brsdq') ?? {value: null};
          // @ts-ignore don't know why this is failing in CI
          const {value: rsdqData} = results
            .filter(r => r.status === 'fulfilled')
            // @ts-ignore don't know why this is failing in CI
            .find(r => r.value!.query === 'rsdq') ?? {value: null};

          // we want to ignore logging data on queries that aren't returning updated data
          if (
            !brsdqData ||
            !rsdqData ||
            brsdqData.deltaRuns === 0 ||
            rsdqData.deltaRuns === 0
          ) {
            return;
          }
          const data = {
            rsdqTime: rsdqData.elapsedMs,
            brsdqTime: brsdqData.elapsedMs,
            bRSDQDelta: brsdqData.elapsedMs - rsdqData.elapsedMs, // a negative number means the bRSDQ was faster
            bucketedRuns: brsdqData.deltaRuns,
            bucketedPoints: brsdqData.deltaPointsTotal,
            sampledRuns: rsdqData.deltaRuns,
            sampledPoints: rsdqData.deltaPointsTotal,
          };
          logToDataDog(`${PERF_LOG_PREFIX} Perf Event: Shadow RSDQ`, data);
        }
      } catch (e) {
        // we don't care if this fails but want to make sure it doesn't take down anything else with it if for some unexpected reason it does
      }
    })
    .catch(err => {
      // swallow this error for now
    });
}
