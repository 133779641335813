import React from 'react';
import {twMerge} from 'tailwind-merge';

import {NumberInput} from './NumberInput';
import {LinePlotSettings} from './types';

export const NumberRangeInput = ({
  minValue,
  maxValue,
  updateSetting,
}: {
  minValue: number | undefined;
  maxValue: number | undefined;
  updateSetting: (settings: Partial<LinePlotSettings>) => void;
}) => {
  // TODO - handle invalid input ranges. ex: max input > min input

  return (
    <div className={twMerge('flex gap-8')}>
      <NumberInput
        placeholder="Min"
        value={minValue}
        onChange={(newVal?: number) => {
          updateSetting({xAxisMin: newVal});
        }}
      />
      <NumberInput
        placeholder="Max"
        value={maxValue}
        onChange={(newVal?: number) => {
          updateSetting({xAxisMax: newVal});
        }}
      />
    </div>
  );
};
