import * as FuzzyMatch from '@wandb/weave/common/util/fuzzyMatch';
import {NavLinkNoCrawl} from '@wandb/weave/common/util/links';
import {addUTCTimezoneIfNotPresent} from '@wandb/weave/common/util/time';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, {useCallback} from 'react';
import {CellInfo, Column} from 'react-table';
import TimeAgo from 'react-timeago';

import {RunsTableFragmentFragment} from '../generated/graphql';
import {Graph} from '../types/graphql';
import {runGroup} from '../util/urls';
import ImageIconHeader from './ImageIconHeader';
import {RunState} from './RunState';
import WBReactTable, {makeColumnSortMethod} from './WBReactTable';

interface RunsTableProps {
  data: Graph<RunsTableRun> | null;
  name: string;
  showUserColumn: boolean;
  /* Pulling the header out when displaying the skeleton loader */
  suppressHeader?: boolean;
  pageSize?: number | null;
  setRunNameSearchStr?: (searchStr: string) => void;
  onChangePageSize?: (pageSize: number) => void;
}

export interface RunsTableRun {
  id: string;
  name: string;
  displayName: string;
  state: string;
  createdAt: string;
  heartbeatAt: string;
  updatedAt: string;
  user: {
    id: string;
    username: string;
  };
  project: {
    id: string;
    name: string;
    entityName: string;
  };
  groupCounts?: number[];
}

export const runsTableFragment = gql`
  fragment RunsTableFragment on Run {
    id
    name
    displayName
    state
    createdAt
    heartbeatAt
    updatedAt
    user {
      id
      username
    }
    project {
      id
      name
      entityName
    }
    groupCounts
  }
`;

export function runsTableRunFromQueryData(
  data?: Graph<RunsTableFragmentFragment> | null
): Graph<RunsTableRun> {
  if (data == null) {
    return {edges: []};
  }
  return {
    edges:
      data.edges.map(run => ({
        node: {
          id: run.node.id,
          name: run.node.name ?? '',
          state: run.node.state ?? '',
          displayName: run.node.displayName ?? '',
          createdAt: run.node.createdAt.toString(),
          heartbeatAt: run.node.heartbeatAt?.toString() ?? '',
          updatedAt: run.node.updatedAt?.toString() ?? '',
          user: {
            id: run.node.user?.id ?? '',
            username: run.node.user?.username ?? '',
          },
          project: {
            id: run.node.project?.id ?? '',
            name: run.node.project?.name ?? '',
            entityName: run.node.project?.entityName ?? '',
          },
          groupCounts: run.node.groupCounts ?? [],
        },
      })) ?? [],
  };
}

const RunsTable: React.FC<RunsTableProps> = React.memo(
  ({
    data,
    name,
    showUserColumn,
    suppressHeader = false,
    pageSize,
    setRunNameSearchStr,
    onChangePageSize,
  }) => {
    const columns = useCallback(
      (searchQuery?: string) => {
        const nameColumn: Column = {
          // ReactTable's sorting will break if 2 columns
          // have the same `accessor` but not unique `id`s.
          // This column needs an `id` that differentiates it from the 'project' column below.
          id: `name`,
          Header: 'Name',
          accessor: 'run',
          sortMethod: makeColumnSortMethod(run =>
            run.displayName.toLowerCase()
          ),
          Cell: (cellInfo: CellInfo) => {
            const run: RunsTableRun = cellInfo.value;
            const project = run.project;
            const groupCounts = run.groupCounts || [];

            let namePieces: string[];
            if (!searchQuery) {
              namePieces = [run.displayName];
            } else {
              namePieces = FuzzyMatch.fuzzyComponentSplit(
                [run.displayName, project.name],
                searchQuery
              )[0];
            }

            if (groupCounts.length > 0) {
              return (
                <NavLinkNoCrawl
                  to={runGroup({
                    entityName: project.entityName,
                    projectName: project.name,
                    name: run.name,
                  })}>
                  <div>
                    {FuzzyMatch.fuzzyMatchHighlightPieces(namePieces)}{' '}
                    <span style={{color: 'gray'}}>
                      {' ('}
                      {groupCounts[0]}
                      {groupCounts[0] === 1 ? ' run)' : ' runs)'}
                    </span>
                  </div>
                </NavLinkNoCrawl>
              );
            } else {
              return (
                <NavLinkNoCrawl
                  to={`/${run.project.entityName}/${run.project.name}/runs/${run.name}`}>
                  {FuzzyMatch.fuzzyMatchHighlightPieces(namePieces)}
                </NavLinkNoCrawl>
              );
            }
          },
        };
        const projectColumn: Column = {
          // ReactTable's sorting will break if 2 columns
          // have the same `accessor` but not unique `id`s.
          // This column needs an `id` that differentiates it from the 'name' column above.
          id: `project`,
          Header: 'Project',
          accessor: 'run',
          sortMethod: makeColumnSortMethod(run =>
            run.project.name.toLowerCase()
          ),
          Cell: (cellInfo: CellInfo) => {
            const run: RunsTableRun = cellInfo.value;
            const project = run.project;

            let namePieces: string[];
            if (!searchQuery) {
              namePieces = [project.name];
            } else {
              namePieces = FuzzyMatch.fuzzyComponentSplit(
                [run.displayName, project.name],
                searchQuery
              )[1];
            }

            return (
              <NavLinkNoCrawl to={`/${project.entityName}/${project.name}`}>
                {FuzzyMatch.fuzzyMatchHighlightPieces(namePieces)}
              </NavLinkNoCrawl>
            );
          },
        };
        const userColumn: Column = {
          Header: 'User',
          accessor: 'run.user.username',
          Cell: (cellInfo: CellInfo) => {
            const username = cellInfo.value;
            return (
              <NavLinkNoCrawl to={`/${username}`}>{username}</NavLinkNoCrawl>
            );
          },
        };
        const stateColumn: Column = {
          Header: 'State',
          accessor: 'state',
          Cell: (cellInfo: CellInfo) => <RunState value={cellInfo.value} />,
        };
        const createdColumn: Column = {
          Header: 'Created',
          accessor: 'createdAt',
        };
        const allColumns = [
          nameColumn,
          projectColumn,
          stateColumn,
          createdColumn,
        ];
        if (showUserColumn) {
          allColumns.push(userColumn);
        }
        return allColumns;
      },
      [showUserColumn]
    );

    const runs = _.map(
      // runs *should* always have a project, but sometimes runs aren't deleted
      // when a project is -- this guards against that case
      (data?.edges || []).filter(n => !!n.node.project),
      n => {
        return {
          searchString: `${n.node.displayName}${n.node.project.name}`,
          row: {
            searchString: `${n.node.displayName}${n.node.project.name}`,
            run: n.node,
            state: n.node.state,
            createdAt: (
              <TimeAgo date={addUTCTimezoneIfNotPresent(n.node.createdAt)} />
            ),
          },
        };
      }
    );

    return (
      <>
        {!suppressHeader && <ImageIconHeader icon="run" text="Runs" />}
        <WBReactTable
          data={runs}
          columns={columns()}
          pageSize={pageSize ?? 10}
          onChangePageSize={onChangePageSize}
          onSearch={setRunNameSearchStr}
        />
      </>
    );
  }
);

export default RunsTable;
