import {useRef} from 'react';

import {keyExistsInQueryString} from '../query-string';

const RENDER_LOGGING_ENABLED = keyExistsInQueryString('RENDER_LOGGING');

const RENDER_PERF_LOG_PREFIX = 'WB_R';

/**
 * This can help in debugging what props/state has changed reference equality, and also warn if the
 * ref equality changed, but the underlying data didn't. This can tell you in some circumstances
 * why a useMemo/useEffect fired, and if a React.memo() is on a component, why that component re-rendered.
 *
 * To use these utilities, import the `useDetectChanges` or `useDetectChangesProps` function in your component
 * Then call it with a description of the component and the props that you want to monitor for changes.
 *
 * Example usages:
 * `useDetectChanges('MyComponent', {myPropName, myOtherPropName})` -> passing in individual props
 * `useDetectChangesProps('MyComponent', props)` -> passing in the full props object
 *
 * Note that to use this, you'll need to add RENDER_LOGGING to the query string, no value
 * needs to be passed in (eg `?RENDER_LOGGING`)
 */
export const useDetectChanges = (
  description: string,
  valObject: Record<string, unknown>
) => {
  const prev = useRef(valObject);
  if (!RENDER_LOGGING_ENABLED) {
    return;
  }
  console.log(`${RENDER_PERF_LOG_PREFIX} ${description} rendered`);
  // note that this doesn't try to detect if a key was removed/added. Since the
  // object you're passing in should be hard coded, that won't happen.
  Object.entries(valObject).forEach(([newKey, newVal]) => {
    const prevVal = prev.current[newKey];
    if (newVal !== prevVal) {
      console.log(
        `${RENDER_PERF_LOG_PREFIX} ${description} changed, ${newKey}:`
      );
      const before = getDiffableStringForVal(prevVal);
      const after = getDiffableStringForVal(newVal);

      console.log(RENDER_PERF_LOG_PREFIX, 'b:', before);
      if (before !== after) {
        // only log if they are different, otherwise these are repetitive
        console.log(RENDER_PERF_LOG_PREFIX, 'a:', after);
      } else {
        console.log(
          `%c${RENDER_PERF_LOG_PREFIX} ^^^ before and after are the same, but the object references are different`,
          `color: red; background: #f0f0f0;`
        );
      }
    }
  });
  prev.current = valObject;
};

/**
 * Since we can't use the above hook in class components, this lets us at least see when they re-render
 */
export const reportRenderInClassComponent = (componentName: string) => {
  if (!RENDER_LOGGING_ENABLED) {
    return;
  }
  console.log(
    `${RENDER_PERF_LOG_PREFIX} ${componentName} rendered, no prop change data available in this class component`
  );
};

function getDiffableStringForVal(val: unknown) {
  if (typeof val === 'function') {
    return val?.toString();
  } else {
    return JSON.stringify(val);
  }
}
