import {isEqual} from 'lodash';
import {useSelector} from 'react-redux';

import {RootState} from '../../../types/redux';
import {Ref as SectionRef} from '../panelBankSectionConfig/types';

export const useSectionSettings = (sectionRef: SectionRef) => {
  const sectionSettings = useSelector(
    makeSectionSettingsSelector(sectionRef),
    isEqual
    // 'makeWorkspaceSettingsSelector' // comment back in for debugging
  );
  return sectionSettings;
};

export const makeSectionSettingsSelector = (sectionRef: SectionRef) => {
  return (state: RootState) => {
    return state.views.parts['panel-bank-section-config'][sectionRef.id]
      .sectionSettings;
  };
};

export const useSectionPanels = (sectionRef?: SectionRef) => {
  return useSelector(makeSectionPanelsSelector(sectionRef));
};

export const makeSectionPanelsSelector = (sectionRef?: SectionRef) => {
  return (state: RootState) => {
    if (!sectionRef) {
      return [];
    }
    return state.views.parts[sectionRef.type][sectionRef.id].panelRefs.map(
      panelRef => state.views.parts[panelRef.type][panelRef.id]
    );
  };
};
