import {apolloClient} from '../apolloClient';
import {envIsIntegration} from '../config';
import {
  EntityFeatureFlagsDocument,
  EntityFeatureFlagsQuery,
  EntityFeatureFlagsQueryVariables,
  OrgType,
  RampIdType,
  useEntityFeatureFlagsQuery,
  useOrganizationFeatureFlagsQuery,
  useViewerFeatureFlagsQuery,
  useViewerOrganizationFeatureFlagsQuery,
} from '../generated/graphql';
import {isAnonymousEntity} from '../state/workspaces/utils';
import {propagateErrorsContext} from './errors';

export type RampFlagStatus = {
  isEnabled: boolean;
  loading: boolean;
};

// TODO: we should auto generate this list from ramp_settings.go's RampFeatures
// list. For now, we have to manually keep this list in sync.
// ALSO TODO: we should also have the server tell us the ramp key *type* so that the
// user just calls useRampFlag('disable-fuzzy-search') and we can figure out the type
// based on the RampSettings' IDType in ramp_settings.go
export type RampKey =
  | 'account-selector'
  | 'disable-fuzzy-search'
  | 'disable-query-merging'
  | 'disable-update-history-key-info-org'
  | 'disable-update-history-key-info-user'
  | 'enable-opfs-cache'
  | 'global-registry'
  | 'gorilla-force-run-outliers-user'
  | 'gorilla-force-run-outliers'
  | 'gorilla-run-outliers-user'
  | 'gorilla-run-outliers'
  | 'large-workspace-mode-org'
  | 'large-workspace-mode-user'
  | 'no-keysets-org'
  | 'no-keysets-user'
  | 'panel-gen-quick-add-org'
  | 'panel-gen-quick-add-user'
  | 'panel-gen-setting-org'
  | 'panel-gen-setting-user'
  | 'project-specific-roles'
  | 'registry-automations'
  | 'sampled-runs-line-plot-notifications'
  | 'shadow-rsdq-queries-user'
  | 'weave-as-a-service-user'
  | 'weave-marketing'
  | 'workspace-settings-drawer-org'
  | 'workspace-settings-drawer-user';

export const useEntityRampFlag = (
  entityName: string,
  rampKey: RampKey,
  rampIDType: RampIdType,
  getEntityFlags: typeof useEntityFeatureFlagsQuery = useEntityFeatureFlagsQuery
) => {
  const {data} = getEntityFlags({
    variables: {teamName: entityName, rampIDType},
    skip: entityName == null || isAnonymousEntity(entityName),
  });
  // All features are enabled for CI
  if (envIsIntegration) {
    return true;
  }
  const teamFlagsGlobalList = data?.entity?.featureFlags;
  if (!teamFlagsGlobalList) {
    // disabling this until we have an actionable use for it. It's very noisy
    // console.warn('useEntityRampFlag: returning false due to no data');
    return false;
  }

  return (
    teamFlagsGlobalList.find(f => f?.rampKey === rampKey)?.isEnabled ?? false
  );
};

export const checkEntityRampFlag = async (
  entityName: string,
  rampKey: RampKey
) => {
  // Skip query if entity is anonymous
  if (isAnonymousEntity(entityName)) {
    return false;
  }

  const {data, errors} = await apolloClient.query<
    EntityFeatureFlagsQuery,
    EntityFeatureFlagsQueryVariables
  >({
    query: EntityFeatureFlagsDocument,
    fetchPolicy: 'no-cache',
    variables: {
      teamName: entityName,
      rampIDType: RampIdType.EntityName,
    },
  });
  const teamFlagsGlobalList = data?.entity?.featureFlags;
  if (!teamFlagsGlobalList || errors) {
    return false;
  }

  return (
    teamFlagsGlobalList.find(f => f?.rampKey === rampKey)?.isEnabled ?? false
  );
};

export const useUserRampFlag = (
  rampKey: RampKey,
  rampIDType: RampIdType,
  getUserFlags: typeof useViewerFeatureFlagsQuery = useViewerFeatureFlagsQuery
) => {
  const {data} = getUserFlags({variables: {rampIDType}});
  if (!data?.viewer?.featureFlags) {
    // disabling this until we have an actionable use for it. It's very noisy
    // console.warn('useUserRampFlag: returning false due to no data');
    return false;
  }
  return (
    data.viewer.featureFlags.find(f => f?.rampKey === rampKey)?.isEnabled ??
    false
  );
};

export const useOrgRampFlagWithLoading = (
  rampKey: RampKey,
  rampIDType: RampIdType,
  getOrgFlags: typeof useViewerOrganizationFeatureFlagsQuery = useViewerOrganizationFeatureFlagsQuery
) => {
  const {data, loading} = getOrgFlags({
    variables: {rampIDType},
  });

  if (loading) {
    return {data: false, loading};
  }

  if (!data?.viewer?.organizations) {
    return {data: false, loading};
  }

  const orgFlagIsEnabled = data.viewer.organizations.some(org =>
    org.featureFlags?.some(f => f?.rampKey === rampKey && f?.isEnabled)
  );
  return {data: orgFlagIsEnabled, loading: false};
};

export const useOrgRampFlag = (
  rampKey: RampKey,
  rampIDType: RampIdType,
  getOrgFlags: typeof useViewerOrganizationFeatureFlagsQuery = useViewerOrganizationFeatureFlagsQuery
) => {
  const ret = useOrgRampFlagWithLoading(rampKey, rampIDType, getOrgFlags);
  return !ret.loading && ret.data;
};

export const useOrgNameRampFlag = (
  orgName: string,
  rampKey: RampKey,
  rampIDType: RampIdType,
  getOrgFlags: typeof useOrganizationFeatureFlagsQuery = useOrganizationFeatureFlagsQuery
) => {
  const {data, loading} = getOrgFlags({
    variables: {orgName, rampIDType},
    skip: orgName == null,
    context: propagateErrorsContext(),
  });

  const orgFeatureFlags = data?.organization?.featureFlags;
  if (loading || orgFeatureFlags == null) {
    return {data: false, loading};
  }
  if (data?.organization?.orgType === OrgType.Personal) {
    return {data: false, loading};
  }

  return {
    data: orgFeatureFlags.find(f => f?.rampKey === rampKey)?.isEnabled ?? false,
    loading,
  };
};
