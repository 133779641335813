import _ from 'lodash';

import {PanelBankConfigState} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {getFoundPanelSpecs, getPanelBankDiff} from '../../../util/panelbank';
import {getDefaultPanelSectionConfig} from '../../../util/panelbankConfigs';
import {
  PANEL_BANK_CUSTOM_CHARTS_NAME,
  PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
  PANEL_BANK_SYSTEM_NAME,
} from '../../../util/panelbankTypes';
import * as Panels from '../../../util/panels';
import {setInShallowClone} from '../../../util/utility';
import {StateType as ExpectedPanelsState} from '../../expectedPanels/reducer';
import * as Normalize from '../normalize';
import {
  PanelBankSectionConfigNormalized,
  Ref as PanelBankSectionConfigRef,
} from '../panelBankSectionConfig/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import * as WorkspaceSettingsTypes from '../workspaceSettings/types';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import {addOrUpdatePanelsToSections} from './addOrUpdatePanelsToSections';
import * as Types from './types';

/**
 * Applies immutable updates to each section in a panel bank
 * with the provided `getUpdatedSection` callback.
 *
 * `getUpdatedSection` will provide a reference to the current
 * section config. If updates are needed, return a new copy of
 * the section with the changes applied. Otherwise, return the
 * current section config as-is.
 */
export const updateSections = (
  state: ViewReducerState,
  ref: Types.Ref,
  getUpdatedSection: (
    curSectionConfig: Readonly<PanelBankSectionConfigNormalized>,
    idx: number
  ) => PanelBankSectionConfigNormalized
): [ViewReducerState, ActionType] => {
  // set up new state
  const newState = setInShallowClone(
    state,
    ['parts', 'panel-bank-section-config'],
    Object.assign({}, state.parts['panel-bank-section-config'])
  );

  // track current sections state to allow undo
  const curSectionConfigs: PanelBankSectionConfigNormalized[] = [];

  // iterate over each section
  state.parts[ref.type][ref.id].sectionRefs.forEach((sectionRef, idx) => {
    const curSectionConfig = state.parts[sectionRef.type][sectionRef.id];
    curSectionConfigs.push(curSectionConfig);
    newState.parts[sectionRef.type][sectionRef.id] = getUpdatedSection(
      curSectionConfig,
      idx
    );
  });

  const inverseAction = ActionsInternal.setAllSections(ref, curSectionConfigs);

  return [newState, inverseAction];
};

/** Processes panel bank config into ready state */
export const readyPanelBank = (draft: ViewReducerState, ref: Types.Ref) => {
  const panelBankConfig = draft.parts[ref.type][ref.id];

  if (panelBankConfig.state === PanelBankConfigState.Ready) {
    return;
  }

  // Special stuff that happens the first time you initialize the PanelBank
  // Sort sections by name, with these special behaviors:
  //   - Pinned sections are first and don't get sorted alphabetically
  //   - Custom Visualizations is first if found
  //   - System is second to last
  //   - Hidden Panels is last

  // Find the Hidden Panels section
  const hiddenSectionRef = panelBankConfig.sectionRefs.slice(-1)[0];
  const prevPanelBankSections = panelBankConfig.sectionRefs.map(
    sectionRef => draft.parts[sectionRef.type][sectionRef.id]
  );
  const legacyCustomVizSectionRefIndex = _.findIndex(prevPanelBankSections, {
    name: PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
  });
  // Find the Custom Visualizations section
  const legacyCustomVizSectionRef =
    legacyCustomVizSectionRefIndex > -1
      ? panelBankConfig.sectionRefs[legacyCustomVizSectionRefIndex]
      : null;

  const pinnedSectionRefs = panelBankConfig.sectionRefs.filter(
    r => draft.parts[r.type][r.id].pinned
  );

  // Sort sections alphabetically by name
  const sortedSectionRefs = panelBankConfig.sectionRefs
    .filter(r => r !== hiddenSectionRef && r !== legacyCustomVizSectionRef)
    .filter(r => !draft.parts[r.type][r.id].pinned)
    .sort((a, b) => {
      const nameA = draft.parts[a.type][a.id].name.toLowerCase();
      const nameB = draft.parts[b.type][b.id].name.toLowerCase();

      // Ensure system is last
      const systemLower = PANEL_BANK_SYSTEM_NAME.toLowerCase();
      if (nameB === systemLower && nameA !== systemLower) {
        return -1;
      } else if (nameB !== systemLower && nameA === systemLower) {
        return 1;
      }

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

  // Add the Custom Visualizations section at the beginning, and the Hidden Panels section at the end
  panelBankConfig.sectionRefs = _.compact(
    pinnedSectionRefs
      .concat(
        legacyCustomVizSectionRef != null ? [legacyCustomVizSectionRef] : []
      )
      .concat(sortedSectionRefs)
      .concat(hiddenSectionRef)
  );
  // Open the first section by default, if nothing is open.
  if (
    panelBankConfig.sectionRefs.length > 0 &&
    panelBankConfig.sectionRefs.every(
      sectionRef => !draft.parts[sectionRef.type][sectionRef.id].isOpen
    )
  ) {
    const firstSectionRef = panelBankConfig.sectionRefs[0];
    draft.parts[firstSectionRef.type][firstSectionRef.id].isOpen = true;
  }

  // Open the Custom Charts section if it exists
  const customChartSectionRef = panelBankConfig.sectionRefs.find(
    a => draft.parts[a.type][a.id].name === PANEL_BANK_CUSTOM_CHARTS_NAME
  );
  if (customChartSectionRef != null) {
    draft.parts[customChartSectionRef.type][customChartSectionRef.id].isOpen =
      true;
  }

  panelBankConfig.state = PanelBankConfigState.Ready;
};

export const sortSectionsByPinning = (
  draft: ViewReducerState,
  ref: Types.Ref
) => {
  const panelBankConfig = draft.parts[ref.type][ref.id];
  const unpinnedsections = panelBankConfig.sectionRefs.filter(
    r => !draft.parts[r.type][r.id].pinned
  );
  const pinnedSections = panelBankConfig.sectionRefs.filter(
    r => draft.parts[r.type][r.id].pinned
  );
  const sectionRefsSortedByPinning = pinnedSections.concat(unpinnedsections);
  const isCurrentSectionRefsDifferent = panelBankConfig.sectionRefs.some(
    (sectionRef, i) => sectionRef !== sectionRefsSortedByPinning[i]
  );
  if (isCurrentSectionRefsDifferent) {
    panelBankConfig.sectionRefs = sectionRefsSortedByPinning;
  }
};

export const diffAndInitPanels = (
  state: ViewReducerState,
  ref: Types.Ref,
  expectedPanelSpecs: ExpectedPanelsState,
  shouldAutoGeneratePanels?: boolean
): Types.UndoPanelAutoGenInfo => {
  // can't diff if there's no expected specs
  if (!expectedPanelSpecs) {
    return;
  }

  // compute panel bank diff
  const panelBankConfig = state.parts[ref.type][ref.id];
  const panelParts = panelBankConfig.sectionRefs.flatMap(sectionRef => {
    const section = state.parts[sectionRef.type][sectionRef.id];
    return section.panelRefs.map(
      panelRef => state.parts[panelRef.type][panelRef.id]
    );
  });
  const foundPanelSpecs = getFoundPanelSpecs(
    panelParts,
    expectedPanelSpecs.apiAddedSpecs
  );
  const keysInPanels = _.compact(panelParts.map(panel => Panels.getKey(panel)));
  const panelBankDiff = getPanelBankDiff(
    expectedPanelSpecs,
    foundPanelSpecs,
    keysInPanels,
    panelBankConfig.state,
    shouldAutoGeneratePanels
  );

  if (!Object.keys(panelBankDiff).length) {
    return;
  }

  // TODO(perf): make immutable so we can move off of immer
  const undoPanelAutoGenInfo = addOrUpdatePanelsToSections(
    panelBankDiff,
    state.parts,
    ref
  );
  readyPanelBank(state, ref);
  sortSectionsByPinning(state, ref);
  return undoPanelAutoGenInfo;
};

export const clearAllPanels = (
  state: ViewReducerState,
  panelBankConfigRef: Types.Ref,
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref
): [ViewReducerState, ActionType] => {
  // track these values for undo
  const prevSectionRefs =
    state.parts[panelBankConfigRef.type][panelBankConfigRef.id].sectionRefs;
  const prevShouldAutoGeneratePanels =
    state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
      .shouldAutoGeneratePanels;

  const newSectionConfigs = [
    getDefaultPanelSectionConfig({name: 'Charts'}),
    getDefaultPanelSectionConfig({name: 'Hidden Panels'}),
  ];
  const {newParts, newSectionRefs} = newSectionConfigs.reduce(
    (acc, section) => {
      const {parts, ref} = Normalize.addObjImmutable(
        acc.newParts,
        'panel-bank-section-config',
        panelBankConfigRef.viewID,
        section
      );
      acc.newParts = parts;
      acc.newSectionRefs.push(ref);
      return acc;
    },
    {
      newParts: state.parts,
      newSectionRefs: [] as PanelBankSectionConfigRef[],
    }
  );

  const newState = {
    ...state,
    parts: {
      ...newParts,
      [panelBankConfigRef.type]: {
        ...newParts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...newParts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs: newSectionRefs, // update pb config to use new sections
        },
      },
    },
  };

  // turn off auto-gen (only if not already off)
  if (prevShouldAutoGeneratePanels !== false) {
    newState.parts[workspaceSettingsRef.type] = {
      ...newParts[workspaceSettingsRef.type],
      [workspaceSettingsRef.id]: {
        ...newParts[workspaceSettingsRef.type][workspaceSettingsRef.id],
        shouldAutoGeneratePanels: false,
      },
    };
  }

  const inverseAction = Actions.clearAllPanelsUndo(
    panelBankConfigRef,
    workspaceSettingsRef,
    prevSectionRefs,
    prevShouldAutoGeneratePanels
  );

  return [newState, inverseAction];
};

export const clearAllPanelsUndo = (
  state: ViewReducerState,
  panelBankConfigRef: Types.Ref,
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref,
  sectionRefs: PanelBankSectionConfigRef[],
  shouldAutoGeneratePanels?: boolean
): [ViewReducerState, ActionType] => {
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [panelBankConfigRef.type]: {
        ...state.parts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...state.parts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs, // reverts sectionRefs
        },
      },
    },
  };

  // revert auto-gen setting (as needed)
  const prevSettings =
    state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id];
  if (prevSettings.shouldAutoGeneratePanels !== shouldAutoGeneratePanels) {
    newState.parts[workspaceSettingsRef.type] = {
      ...state.parts[workspaceSettingsRef.type],
      [workspaceSettingsRef.id]: {
        ...prevSettings,
        shouldAutoGeneratePanels,
      },
    };
  }

  const inverseAction = Actions.clearAllPanels(
    panelBankConfigRef,
    workspaceSettingsRef
  );

  return [newState, inverseAction];
};

export const updateAllLinePlotSectionSettings = (
  state: ViewReducerState,
  ref: Types.Ref,
  settings: Partial<LinePlotSettings> | undefined
): [ViewReducerState, ActionType] => {
  const newState = setInShallowClone(
    state,
    ['parts', 'panel-bank-section-config'],
    Object.assign({}, state.parts['panel-bank-section-config'])
  );

  const prevSectionConfigs: PanelBankSectionConfigNormalized[] = [];

  state.parts[ref.type][ref.id].sectionRefs.forEach((sectionRef, idx) => {
    const section = state.parts[sectionRef.type][sectionRef.id];
    prevSectionConfigs.push(section);

    newState.parts[sectionRef.type][sectionRef.id] = {
      ...newState.parts[sectionRef.type][sectionRef.id],
      sectionSettings: {
        ...newState.parts[sectionRef.type][sectionRef.id].sectionSettings,
        linePlot: {
          ...(newState.parts[sectionRef.type][sectionRef.id].sectionSettings
            ?.linePlot ?? {}),
          ...settings,
        },
      },
    };
  });

  const inverseAction = ActionsInternal.setAllSections(ref, prevSectionConfigs);

  return [newState, inverseAction];
};
