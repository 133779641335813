import {SmoothingTypeValues} from '../../elements/SmoothingConfig';
import {Settings, SmoothingSettings, XAxisValues} from './types';

export const xAxisLabels: {[key: string]: string} = {
  [XAxisValues.Step]: 'Step',
  [XAxisValues.RunTime]: 'Relative Time (Process)',
  [XAxisValues.AbsoluteRunTime]: 'Relative Time (Wall)',
  [XAxisValues.Timestamp]: 'Wall Time',
};

export const DEFAULT_ORGANZED_SETTINGS = {};

export const DEFAULT_X_AXIS_OPTION = {
  value: XAxisValues.Step,
  label: xAxisLabels[XAxisValues.Step],
};

export const DEFAULT_X_AXIS_SETTINGS = {
  xAxis: XAxisValues.Step,
  xAxisMin: undefined,
  xAxisMax: undefined,
};

export const DEFAULT_X_AXIS_OPTION_VALUES: string[] = [
  XAxisValues.RunTime,
  XAxisValues.AbsoluteRunTime,
  XAxisValues.Timestamp,
  XAxisValues.Step,
];

export const DEFAULT_SMOOTHING_SETTINGS: {
  smoothingWeight: number;
  smoothingType: SmoothingTypeValues;
} = {
  smoothingWeight: 0,
  smoothingType: 'exponentialTimeWeighted',
};

export const EMPTY_SMOOTHING_SETTINGS: SmoothingSettings = {
  smoothingWeight: 0,
  smoothingType: 'exponentialTimeWeighted',
};

export const EMPTY_SETTINGS: Settings = {
  ...EMPTY_SMOOTHING_SETTINGS,
  xAxis: XAxisValues.Step,
  ignoreOutliers: false,
  xAxisActive: false,
  smoothingActive: false,
  useRunsTableGroupingInPanels: true,
};
