import {envIsLocal} from '../config';
import {RampIdType} from '../generated/graphql';
import {
  checkEntityRampFlag,
  RampFlagStatus,
  useEntityRampFlag,
  useOrgNameRampFlag,
  useOrgRampFlag,
  useOrgRampFlagWithLoading,
  useUserRampFlag,
} from './useRampFlag';

/*
Docs for ramp feature flags:
https://www.notion.so/wandbai/Ramp-Feature-Flag-Documentation-da56991072df4c8d980cd36804f52ded
*/

export const useRampFlagShadowRSDQ = () => {
  return useUserRampFlag('shadow-rsdq-queries-user', RampIdType.UserName);
};

export const useRampFlagDisableFuzzySearch = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'disable-fuzzy-search',
    RampIdType.EntityName
  );
};

export const useRampFlagDisableQueryMerging = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'disable-query-merging',
    RampIdType.EntityName
  );
};

export const useRampFlagDisableUpdateHistoryKeysInfo = () => {
  const orgFlag = useOrgRampFlag(
    'disable-update-history-key-info-org',
    RampIdType.OrgName
  );
  const userFlag = useUserRampFlag(
    'disable-update-history-key-info-user',
    RampIdType.UserName
  );
  return orgFlag || userFlag;
};

export const checkRampFlagEnableOPFSCache = async (
  entityName: string
): Promise<boolean> => {
  return await checkEntityRampFlag(entityName, 'enable-opfs-cache');
};

export const useRampFlagEnableOPFSCache = (entityName: string): boolean => {
  return useEntityRampFlag(
    entityName,
    'enable-opfs-cache',
    RampIdType.EntityName
  );
};

export const useRampFlagGorillaOutliersAvailable = () => {
  const userFlag = useUserRampFlag(
    'gorilla-run-outliers-user',
    RampIdType.UserName
  );
  const orgFlag = useOrgRampFlag('gorilla-run-outliers', RampIdType.OrgName);

  // Make the feature available for Server
  if (envIsLocal) {
    return true;
  }
  return userFlag || orgFlag;
};

export const useRampFlagDefaultGorillaOutliers = () => {
  const userFlag = useUserRampFlag(
    'gorilla-force-run-outliers-user',
    RampIdType.UserName
  );
  const orgFlag = useOrgRampFlag(
    'gorilla-force-run-outliers',
    RampIdType.OrgName
  );

  return userFlag || orgFlag;
};

export const useRampFlagLargeWorkspaceMode = () => {
  const userFlag = useUserRampFlag(
    'large-workspace-mode-user',
    RampIdType.UserName
  );
  const orgFlag = useOrgRampFlag(
    'large-workspace-mode-org',
    RampIdType.OrgName
  );
  return userFlag || orgFlag;
};

// in W&B server yet
export const useRampFlagAccountSelector = () => {
  if (envIsLocal) {
    return false;
  }
  return true;
};

export const useRampFlagGlobalRegistry = (orgName: string) => {
  const {data: orgGlobalRegistryFlag, loading} = useOrgNameRampFlag(
    orgName,
    'global-registry',
    RampIdType.OrgName
  );
  return {data: orgGlobalRegistryFlag ?? false, loading};
};

export const useRampFlagRegistryAutomations = (): RampFlagStatus => {
  const {data, loading} = useOrgRampFlagWithLoading(
    'registry-automations',
    RampIdType.OrgName
  );
  return {isEnabled: data, loading};
};

export const useRampFlagNoKeysets = () => {
  const userFlag = useUserRampFlag('no-keysets-user', RampIdType.UserName);
  const orgFlag = useOrgRampFlag('no-keysets-org', RampIdType.OrgName);
  // Note that this || means that if it's turned on by one flag,
  // but turned off in another, the user will see the feature as being on
  return userFlag || orgFlag;
};

export const useRampFlagWorkspaceSettingsDrawer = () => {
  const userFlag = useUserRampFlag(
    'workspace-settings-drawer-user',
    RampIdType.UserName
  );
  const orgFlag = useOrgRampFlag(
    'workspace-settings-drawer-org',
    RampIdType.OrgName
  );

  // Intentionally disabling for server until feature is ready for private preview
  if (envIsLocal) {
    return false;
  }

  return userFlag || orgFlag;
};

export const useRampFlagWeaveAsAService = () => {
  const userFlag = useUserRampFlag(
    'weave-as-a-service-user',
    RampIdType.UserName
  );

  // disabling for server
  if (envIsLocal) {
    return false;
  }

  return userFlag;
};

export const useRampFlagWeaveMarketing = () => {
  const userFlag = useOrgRampFlag('weave-marketing', RampIdType.OrgName);

  // disabling for server
  if (envIsLocal) {
    return false;
  }

  return userFlag;
};

export const useRampFlagPanelGenQuickAdd = () => {
  const orgFlag = useOrgRampFlag('panel-gen-quick-add-org', RampIdType.OrgName);
  const userFlag = useUserRampFlag(
    'panel-gen-quick-add-user',
    RampIdType.UserName
  );
  return orgFlag || userFlag;
};

export const useRampFlagPanelGenSetting = () => {
  const orgFlag = useOrgRampFlag('panel-gen-setting-org', RampIdType.OrgName);
  const userFlag = useUserRampFlag(
    'panel-gen-setting-user',
    RampIdType.UserName
  );
  return orgFlag || userFlag;
};

export const useRampProjectSpecificRoles = () => {
  const orgFlag = useOrgRampFlag('project-specific-roles', RampIdType.OrgName);

  // Disable for server while feature is in development
  if (envIsLocal) {
    return false;
  }

  return orgFlag;
};
