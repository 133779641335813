import React, {createContext, useContext, useMemo} from 'react';

import type {Ref} from '../../state/views/panelBankConfig/types';
import {useDetectChanges} from '../../util/react-help/detectReactPropChanges';
import {usePanelBankActions} from './usePanelBankActions';

type PanelBankConfigProviderProps = {
  panelBankConfigRef: Ref;
  children: React.ReactNode;
};

type PanelBankConfigProviderValue = {
  panelBankActions: ReturnType<typeof usePanelBankActions>;
  panelBankConfigRef: Ref;
};

const PanelBankConfigRefContext = createContext<
  PanelBankConfigProviderValue | undefined
>(undefined);

export const PanelBankConfigRefProvider = ({
  panelBankConfigRef,
  children,
}: PanelBankConfigProviderProps) => {
  const panelBankActions = usePanelBankActions(panelBankConfigRef);
  const value = useMemo(
    () => ({
      panelBankActions,
      panelBankConfigRef,
    }),
    [panelBankActions, panelBankConfigRef]
  );

  useDetectChanges('PanelBankConfigRefProvider', {
    panelBankActions,
    panelBankConfigRef,
  });

  return (
    <PanelBankConfigRefContext.Provider value={value}>
      {children}
    </PanelBankConfigRefContext.Provider>
  );
};

export const usePanelBankConfigRefContext = () => {
  const value = useContext(PanelBankConfigRefContext);

  if (value == null) {
    throw new Error(
      'usePanelBankConfigRefContext must be used within a PanelBankConfigRefContext'
    );
  }

  return value;
};
