import {OrganizedSettings} from '../../PanelBank/types';

export const getLinePlotSettings = (organizedSettings?: OrganizedSettings) => {
  const linePlotSettings = organizedSettings?.linePlot;

  return {
    ignoreOutliers: linePlotSettings?.ignoreOutliers,
    xAxis: linePlotSettings?.xAxis,
    xAxisMin: linePlotSettings?.xAxisMin,
    xAxisMax: linePlotSettings?.xAxisMax,
    smoothingWeight: linePlotSettings?.smoothingWeight,
    smoothingType: linePlotSettings?.smoothingType,
    suppressLegends: linePlotSettings?.suppressLegends,
  };
};
