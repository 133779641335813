import {TextField} from '@wandb/weave/components/Form/TextField';
import React, {useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';

export const NumberInput = ({
  className,
  placeholder,
  value,
  onChange,
}: {
  className?: string;
  placeholder: string;
  value: number | undefined;
  onChange: (newVal?: number) => void;
}) => {
  const [inputStr, setInputStr] = useState(getStrValue(value));

  const focusedRef = useRef(false);
  useEffect(() => {
    if (!focusedRef.current) {
      focusedRef.current = true;
      setInputStr(getStrValue(value));
    }
  }, [value]);

  return (
    <div className={twMerge('w-[5rem]', className)}>
      <TextField
        type="number"
        placeholder={placeholder}
        value={inputStr}
        onBlur={() => {
          focusedRef.current = false;
          setInputStr(getStrValue(value));
        }}
        onChange={(newValStr: string) => {
          setInputStr(newValStr);

          const newValInt = parseInt(newValStr, 10);
          if (Number.isNaN(newValInt)) {
            onChange(undefined);
            return;
          }
          onChange(newValInt);
        }}
      />
    </div>
  );
};

const getStrValue = (val: number | undefined) => {
  return val == null ? '' : val.toString();
};
