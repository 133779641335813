import {ID} from '@wandb/weave/common/util/id';
import {includes} from 'lodash';

import {
  OrganizationPrefix,
  PanelBankConfig,
  PanelBankConfigState,
  PanelBankSectionConfig,
  PanelBankSettings,
  SectionPanelSorting,
} from '../components/PanelBank/types';
import {EMPTY_SETTINGS} from '../components/WorkspaceDrawer/Settings/defaults';
import {
  PANEL_BANK_CHARTS_NAME,
  PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
  PANEL_BANK_MEDIA_NAME,
  PANEL_BANK_TABLES_NAME,
} from './panelbankTypes';

export const EMPTY_PANEL_BANK_SETTINGS: PanelBankSettings = {
  autoOrganizePrefix: OrganizationPrefix.FirstPrefix,
  showEmptySections: false,
  sortAlphabetically: false,
};

export const EMPTY_PANEL_BANK_CONFIG: PanelBankConfig = {
  state: PanelBankConfigState.Init,
  settings: EMPTY_PANEL_BANK_SETTINGS,
  // default includes a 'Hidden Panels' section
  sections: [
    {
      ...getDefaultPanelSectionConfig(),
      name: 'Hidden Panels',
    },
  ],
};

export const EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT: PanelBankSectionConfig =
  getDefaultPanelSectionConfig({name: 'Report Panels', type: 'grid'});

export function getDefaultPanelSectionConfig(args?: {
  name?: string;
  type?: 'grid' | 'flow';
}): PanelBankSectionConfig {
  const sectionName = args?.name || 'Panel Section';
  return {
    __id__: ID(),
    name: sectionName,
    isOpen:
      includes(
        [PANEL_BANK_CHARTS_NAME, PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME],
        sectionName
      ) || isMediaSectionName(sectionName), // open by default
    panels: [],
    type: args?.type || 'flow',
    flowConfig: {
      snapToColumns: true,
      columnsPerPage: isMediaSectionName(sectionName) ? 1 : 3,
      rowsPerPage: 2,
      gutterWidth: 16,
      boxWidth: 460,
      boxHeight: 300,
    },
    sorted: SectionPanelSorting.None,
    localPanelSettings: EMPTY_SETTINGS,
    pinned: false,
  };
}

function isMediaSectionName(name: string): boolean {
  return (
    name === PANEL_BANK_MEDIA_NAME ||
    name === PANEL_BANK_TABLES_NAME ||
    name.startsWith(`${PANEL_BANK_MEDIA_NAME}/`)
  );
}
